import * as FullStory from '@fullstory/browser'
import marketingEventsConstants from 'src/constants/marketingEvents'
import { InviteUser } from '../interfaces/InviteUser'

export const inviteUserStep2 = (payload: InviteUser) => {
    if (process.env.NEXT_PUBLIC_FULLSTORY_ORG_ID) {
        const data = {
            invite_user_str: payload.inviteUser,
            operator_location_strs: payload.operatorLocations
        }

        FullStory.event(marketingEventsConstants.FULLSTORY_INVITE_USER_STEP_2, data)
    }
}
