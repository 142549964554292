import marketingEventsConstants from 'src/constants/marketingEvents'
import { OrderCompleted } from '../interfaces/OrderCompleted'
import * as FullStory from '@fullstory/browser'

export const orderCompleted = (payload: OrderCompleted) => {
    if (process.env.NEXT_PUBLIC_FULLSTORY_ORG_ID) {
        const { orders, cartId, customerLocation } = payload

        orders?.forEach((order) => {
            const eventData = {
                order_id: order.id,
                cart_id_str: String(cartId),
                vendor_name_str: order.vendor.name,
                operator_location_str: customerLocation?.name,
                currency_str: marketingEventsConstants.CURRENCY_USD,
                value_real: order.subtotal.total,
                revenue_real: order.subtotal.total,
                products: order.items.map((item) => ({
                    product_id: item.id,
                    name: item.product.name,
                    currency: marketingEventsConstants.CURRENCY_USD,
                    price: item.price,
                    category: item.quantityUnit.name,
                    quantity: item.quantity
                }))
            }

            return FullStory.event(marketingEventsConstants.FULLSTORY_ORDER_COMPLETED, eventData)
        })
    }
}
