const privateRoutes: string[] = [
    '/dashboard',
    '/allboarding',
    '/orders',
    '/orders/[id]',
    '/invoices',
    '/vendors',
    '/vendors/[id]',
    '/carts',
    '/carts/[id]',
    '/checkout',
    '/checkout/[id]',
    '/order-guides',
    '/order-guides/[[...id]]',
    '/vendors/[id]/products/[productId]'
]

export default privateRoutes
