import { createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit'
import { SimpleVendorProduct } from 'gwy-sdk/gwy/src/api'
import { shoppingListApi } from '@api/shoppingList'
import { vendorProductsApi } from '@api/vendorProducts'

export interface VendorProductPricesInitialStateInterface {
    requests: Record<string, boolean>
    error?: SerializedError
    vendorProductPrices: SimpleVendorProduct[]
    vendorProductIdsToFetchPrice: string[]
}

interface UpdatePricePayload {
    productId: string
    priceId: string
    price: number
    margin: number
}

const initialState: VendorProductPricesInitialStateInterface = {
    requests: {
        getPricesByListOfProductIds: false
    },
    vendorProductPrices: [],
    vendorProductIdsToFetchPrice: []
}

const vendorProductPricesSlice = createSlice({
    name: 'vendorProductPrices',
    initialState,
    reducers: {
        resetVendorProductIdsToFetchPrice: (state) => {
            state.vendorProductIdsToFetchPrice = []
        },
        resetVendorProductPrices: (state) => {
            state.vendorProductPrices = []
        },
        setVendorProductIdsToFetchPrice: (state, action) => {
            state.vendorProductIdsToFetchPrice = action.payload
        },
        updateSpecificVendorProductPrice: (state, action: PayloadAction<UpdatePricePayload>) => {
            const { productId, priceId, price, margin } = action.payload
            const product = state.vendorProductPrices.find((p) => p.id === productId)
            if (product) {
                const priceIndex = product.prices.findIndex((price) => price.id === priceId)
                if (priceIndex !== -1) {
                    product.prices[priceIndex].price = price
                    product.prices[priceIndex].margin = margin
                    product.prices[priceIndex].packaging.price = price
                }
            }
        },
        replaceSpecificVendorProductPrice: (state, action) => {
            const { prices, id } = action.payload[0]
            const product = state.vendorProductPrices.find((p) => p.id === id)
            if (product) {
                product.prices = prices
            }
        }
    },
    extraReducers: (builder) => {
        builder.addMatcher(vendorProductsApi.endpoints.getProductsPriceByIds.matchFulfilled, (state, action) => {
            state.vendorProductPrices = state.vendorProductPrices.concat(action.payload.response)
        })

        /**
         * We're listening to when getting products from catalog finishes and saving the ids that were fetched
         */
        builder.addMatcher(vendorProductsApi.endpoints.searchVendorProducts.matchFulfilled, (state, action) => {
            state.vendorProductIdsToFetchPrice = action.payload.response.map((vendorProduct) => vendorProduct.id)
        })

        builder.addMatcher(shoppingListApi.endpoints.getItems.matchFulfilled, (state, action) => {
            state.vendorProductIdsToFetchPrice = action.payload.response.map(
                (vendorProduct) => vendorProduct.product.id
            )
        })
    }
})

export const {
    resetVendorProductIdsToFetchPrice,
    setVendorProductIdsToFetchPrice,
    resetVendorProductPrices,
    updateSpecificVendorProductPrice,
    replaceSpecificVendorProductPrice
} = vendorProductPricesSlice.actions

export default vendorProductPricesSlice.reducer
