import { createSlice, SerializedError } from '@reduxjs/toolkit'
import { downloadInvoices } from '@actions/invoices'
import { Invoice } from 'gwy-sdk'

export interface InvoicesSliceInitialStateInterface {
    requests: Record<string, boolean>
    invoiceToPay?: Invoice
    error?: SerializedError
}

const initialState: InvoicesSliceInitialStateInterface = {
    requests: {
        downloadInvoices: false
    }
}

const invoicesSlice = createSlice({
    name: 'invoices',
    initialState,
    reducers: {
        setInvoiceToPay: (state, action) => {
            state.invoiceToPay = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            (action) => [downloadInvoices.pending.type].includes(action.type),
            (state, action) => {
                const requestType = action.type.split('/')[0]
                state.requests[requestType] = true
            }
        )

        builder.addMatcher(
            (action) => [downloadInvoices.fulfilled.type].includes(action.type),
            (state, action) => {
                const requestType = action.type.split('/')[0]
                state.requests[requestType] = false
            }
        )

        builder.addMatcher(
            (action) => [downloadInvoices.rejected.type].includes(action.type),
            (state, action) => {
                const requestType = action.type.split('/')[0]
                state.requests[requestType] = false
                state.error = action.error
            }
        )
    }
})

export const { setInvoiceToPay } = invoicesSlice.actions

export default invoicesSlice.reducer
