import marketingEventsConstants from 'src/constants/marketingEvents'
import * as FullStory from '@fullstory/browser'
import { ProductListViewed } from '../interfaces/ProductListViewed'

export const productListViewed = (payload: ProductListViewed) => {
    if (process.env.NEXT_PUBLIC_FULLSTORY_ORG_ID) {
        const { itemListId, itemListName } = payload

        const data = {
            item_list_id_str: String(itemListId),
            item_list_name_str: itemListName
        }

        FullStory.event(marketingEventsConstants.FULLSTORY_PRODUCT_LIST_VIEWED, data)
    }
}
