enum cartsConstants {
    GET_CART = 'getCart',
    GET_LAST_OPENED_CART = 'getLastOpenedCart',
    UPDATE_CART = 'updateCart',

    CART_OPEN_KEY = 'open',
    CART_CLOSED_KEY = 'closed'
}

export default cartsConstants
