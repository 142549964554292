import DefaultHeaders from '../interfaces/DefaultHeaders'

const defaultHeaders = () => {
    const defaultHeaders: DefaultHeaders = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-TOKEN': '',
        brand: 'dashos',
        deviceType: 'browser',
        deviceId: typeof window !== 'undefined' ? window.navigator.userAgent : '',
        pushId: '',
        gmt: String((new Date().getTimezoneOffset() / 60) * -1)
    }

    return defaultHeaders
}

export default defaultHeaders
