enum vendorProductsConstants {
    SORTING_OPTION_RECENTLY_ADDED = '3',

    GET_VENDOR_PRODUCT_FROM_CATALOG = 'getVendorProductFromCatalog',
    SEARCH_VENDOR_PRODUCTS = 'searchVendorProducts',
    GET_PRICES_BY_LIST_OF_PRODUCT_IDS = 'getPricesByListOfProductIds',

    FILTERS_LAST_WEEK = 'Last 7 days',
    FILTERS_LAST_MONTH = 'Last 30 days',
    FILTERS_LAST_3_MONTHS = 'Last 3 months',
    FILTERS_LAST_12_MONTHS = 'Last 12 months',

    TYPE_ITEM = 'TYPE_ITEM',
    TYPE_HISTORY = 'TYPE_HISTORY',
    TYPE_SEARCH = 'TYPE_SEARCH'
}

export default vendorProductsConstants
