import { createSlice, SerializedError } from '@reduxjs/toolkit'
import { Cart } from 'gwy-sdk'
import { cartApi } from '@api/cart'
import { useGetCustomerLocation } from '@hooks/customerLocation/useGetCustomerLocation'

export interface CartsInitialStateInterface {
    cart?: Cart
    error?: SerializedError
}

const initialState: CartsInitialStateInterface = {}

export const cartsSlice = createSlice({
    name: 'carts',
    initialState,
    reducers: {
        setCart: (state, action) => {
            state.cart = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(cartApi.endpoints.getLastOpenedCart.matchFulfilled, (state, action) => {
                const customerLocationId = useGetCustomerLocation()
                const { originalArgs } = action.meta.arg
                if (customerLocationId === originalArgs) {
                    state.cart = action.payload
                }
            })

            .addMatcher(cartApi.endpoints.updateCart.matchFulfilled, (state, action) => {
                const cartId = action.meta.arg.originalArgs.id
                if (state.cart?.id === cartId) {
                    state.cart = action.payload
                }
            })
    }
})

export const { setCart } = cartsSlice.actions

export default cartsSlice.reducer
