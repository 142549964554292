import { User, Vendor } from 'gwy-sdk'

export const userProperties = (payload: User, vendors: Vendor[]) => {
    const { uuid, brand, email } = payload

    if (process.env.NEXT_PUBLIC_GA4_KEY) {
        gtag('set', 'user_properties', {
            uuid,
            displayName: email.includes('morsum')
                ? 'anchovi-' + String(uuid).slice(-17)
                : 'user-' + String(uuid).slice(-17),
            dashRole: brand.details.role?.name,
            vendorName: vendors.map((v) => v.name)
        })
    }
}
