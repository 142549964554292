import React from 'react'
import { useAppDispatch } from '@hooks'
import { hideGenericInfoSnackbar } from '@slices/snackbars'
import { Snackbar } from '@mui/material'

export interface InfoSnackbarInterface {
    open: boolean
    message?: string
}

const InfoSnackbar: React.FC<InfoSnackbarInterface> = (props) => {
    const dispatch = useAppDispatch()
    const { message, open } = props

    return (
        <Snackbar
            onClose={() => {
                dispatch(hideGenericInfoSnackbar())
            }}
            open={open}
            autoHideDuration={2500}
            message={message}
            ClickAwayListenerProps={{
                mouseEvent: false,
                touchEvent: false
            }}
        />
    )
}

export default InfoSnackbar
