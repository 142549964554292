import * as FullStory from '@fullstory/browser'
import marketingEventsConstants from 'src/constants/marketingEvents'
import { MarginEdit } from '../interfaces/MarginEdit'

export const marginEdit = (payload: MarginEdit) => {
    if (process.env.NEXT_PUBLIC_FULLSTORY_ORG_ID) {
        const {
            marginPrevious,
            marginNew,
            itemId,
            externalId,
            vendorName,
            itemListId,
            itemListName,
            brand,
            name,
            value,
            price,
            itemCategory,
            position,
            url
        } = payload

        const data = {
            margin_previous_real: marginPrevious,
            margin_new_real: marginNew,
            item_id_str: itemId,
            external_id_str: externalId,
            vendor_name_str: vendorName,
            item_list_id_str: itemListId,
            item_list_name_str: itemListName,
            brand_str: brand,
            name_str: name,
            currency_str: marketingEventsConstants.CURRENCY_USD,
            value_real: value,
            price_real: price,
            item_category_str: itemCategory,
            position_int: position,
            url_str: url
        }

        FullStory.event(marketingEventsConstants.FULLSTORY_MARGIN_EDIT, data)
    }
}
