import marketingEventsConstants from 'src/constants/marketingEvents'
import * as FullStory from '@fullstory/browser'
import { OrderGuideUploadInitiated } from '../interfaces/OrderGuideUploadInitiated'

export const orderGuideUploadInitiated = (payload: OrderGuideUploadInitiated) => {
    if (process.env.NEXT_PUBLIC_FULLSTORY_ORG_ID) {
        const { vendorName, operatorLocationName, uploadedAsset } = payload

        const data = {
            vendor_name_str: vendorName,
            operator_location_name_str: operatorLocationName,
            uploaded_asset_str: uploadedAsset
        }

        FullStory.event(marketingEventsConstants.FULLSTORY_ORDER_GUIDE_UPLOAD_INITIATED, data)
    }
}
