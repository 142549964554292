import { SuppliersProductsRequest, InventoryProductsResponse } from 'gwy-sdk'
import { baseApi } from './baseApi'

interface GetVendorProductInventoryInterface {
    externalSupplierId: string
    products: SuppliersProductsRequest
}

export const vendorProductInventoryApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getVendorProductInventory: builder.query<InventoryProductsResponse, GetVendorProductInventoryInterface>({
            query: (request) => {
                const { externalSupplierId, products } = request

                return {
                    url: `onhand/suppliers/${externalSupplierId}/products`,
                    method: 'post',
                    body: products
                }
            }
        })
    })
})

export const { useLazyGetVendorProductInventoryQuery } = vendorProductInventoryApi
