import marketingEventsConstants from 'src/constants/marketingEvents'
import * as FullStory from '@fullstory/browser'
import { OrderGuideUploadStarted } from '../interfaces/OrderGuideUploadStarted'

export const orderGuideUploadStarted = (payload: OrderGuideUploadStarted) => {
    if (process.env.NEXT_PUBLIC_FULLSTORY_ORG_ID) {
        const { vendorName, operatorLocationName } = payload

        const data = {
            vendor_name_str: vendorName,
            operator_location_name_str: operatorLocationName
        }

        FullStory.event(marketingEventsConstants.FULLSTORY_ORDER_GUIDE_UPLOAD_STARTED, data)
    }
}
