import { serializeAPIError } from './serializeAPIError'

export const handleThunkError = (error: any, cb: Function) => {
    const serialized = serializeAPIError(error)
    if (serialized != null) {
        return cb(serialized)
    }

    throw error
}
