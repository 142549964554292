import * as FullStory from '@fullstory/browser'
import marketingEventsConstants from 'src/constants/marketingEvents'
import { InvoiceDownload } from '../interfaces/InvoiceDownload'

export const invoiceDownload = (payload: InvoiceDownload) => {
    if (process.env.NEXT_PUBLIC_FULLSTORY_ORG_ID) {
        const { invoiceId, invoiceNumber, orderId, vendorName, itemListId, itemListName } = payload

        const data = {
            invoice_id: invoiceId,
            invoice_number_str: invoiceNumber,
            order_id_str: orderId,
            vendor_name_str: vendorName,
            item_list_id_str: itemListId,
            item_list_name_str: itemListName
        }

        FullStory.event(marketingEventsConstants.FULLSTORY_INVOICE_DOWNLOAD, data)
    }
}
