import * as FullStory from '@fullstory/browser'
import { User, Vendor } from 'gwy-sdk'

export const identify = (payload: User, vendors: Vendor[]) => {
    const { uuid, brand, email } = payload

    if (process.env.NEXT_PUBLIC_FULLSTORY_ORG_ID) {
        FullStory.identify(String(uuid), {
            displayName: email.includes('morsum')
                ? 'anchovi-' + String(uuid).slice(-17)
                : 'user-' + String(uuid).slice(-17),
            dashRole_str: brand.details.role?.name,
            vendorName_strs: vendors.map((v) => v.name)
        })
    }
}
