import React from 'react'
import { Alert, Snackbar } from '@mui/material'
import { useAppDispatch } from '@hooks'
import { hideGenericErrorSnackbar } from '@slices/snackbars'

export interface ErrorSnackbarInterface {
    open: boolean
    message: string
}

const ErrorSnackbar: React.FC<ErrorSnackbarInterface> = (props) => {
    const dispatch = useAppDispatch()
    const { message, open } = props

    const handleClose = () => {
        dispatch(hideGenericErrorSnackbar())
    }

    return (
        <Snackbar
            onClose={handleClose}
            open={open}
            autoHideDuration={5000}
            ClickAwayListenerProps={{
                mouseEvent: false,
                touchEvent: false
            }}
        >
            <Alert
                severity={'error'}
                onClose={handleClose}
                variant={'filled'}
            >
                {message}
            </Alert>
        </Snackbar>
    )
}

export default ErrorSnackbar
