import React from 'react'
import { Typography, Box, Link } from '@mui/material'

import ImgMobileApp from '@assets/img-mobile-app.png'
import ImgMobileAppIos from '@assets/img-btn-store-ios.png'
import ImgMobileAppAndroid from '@assets/img-btn-store-android.png'
import ImgPoweredByAnchovi from '@assets/img-powered-by-anchovi.png'
import Image from 'next/legacy/image'

const MobileView: React.FC = () => {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center'
            }}
        >
            <Box sx={{ textAlign: 'center', margin: '40px 0 16px 0' }}>
                <Typography variant={'h4'}>
                    Bring your shopping <br /> anywhere
                </Typography>
            </Box>

            <Box sx={{ textAlign: 'center' }}>
                <Typography
                    variant={'body1'}
                    color={'text.primary'}
                >
                    Mobile app is now available on all iOS and
                    <br /> Android devices
                </Typography>
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'row', margin: '24px 0', gap: 2 }}>
                <Link
                    href={process.env.NEXT_PUBLIC_LINK_APPLE}
                    target="_blank"
                >
                    <Image
                        src={ImgMobileAppIos}
                        width={156}
                        height={48}
                        alt="Download on the App Store"
                    />
                </Link>

                <Link
                    href={process.env.NEXT_PUBLIC_LINK_ANDROID}
                    target="_blank"
                >
                    <Image
                        src={ImgMobileAppAndroid}
                        width={156}
                        height={48}
                        alt="Get it on Google Play"
                    />
                </Link>
            </Box>

            <Box sx={{ marginTop: '36px' }}>
                <Image
                    src={ImgMobileApp}
                    alt="Preview of Anchovi mobile app"
                    width={320}
                    height={290}
                />
            </Box>

            <Box sx={{ marginTop: '128px' }}>
                <Image
                    src={ImgPoweredByAnchovi}
                    width={182}
                    height={20}
                    alt="Powered by Anchovi"
                />
            </Box>
        </Box>
    )
}

export default MobileView
