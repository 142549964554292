import * as FullStory from '@fullstory/browser'
import marketingEventsConstants from 'src/constants/marketingEvents'
import { InviteUserCompleted } from '../interfaces/InviteUserCompleted'

export const inviteUserCompleted = (payload: InviteUserCompleted) => {
    if (process.env.NEXT_PUBLIC_FULLSTORY_ORG_ID) {
        const data = {
            invite_user_str: payload.inviteUser,
            operator_location_strs: payload.operatorLocations,
            role: payload.role
        }

        FullStory.event(marketingEventsConstants.FULLSTORY_INVITE_USER_COMPLETED, data)
    }
}
