import marketingEventsConstants from 'src/constants/marketingEvents'
import { ProductAdded } from '../interfaces/ProductAdded'

export const addToCart = (payload: ProductAdded) => {
    if (process.env.NEXT_PUBLIC_GA4_KEY) {
        const { cartId, orderedPrices, vendorProduct } = payload

        const getPrices =
            orderedPrices && orderedPrices.find((price) => price.packaging.unit.id == String(payload.priceId))

        const fullPrice = getPrices && getPrices?.price * payload.quantity

        const data = {
            currency: marketingEventsConstants.CURRENCY_USD,
            value: Number(fullPrice?.toFixed(2)),
            items: [
                {
                    item_id: vendorProduct?.id,
                    external_id: vendorProduct?.externalId,
                    vendor_name: vendorProduct?.vendor?.name,
                    item_name: vendorProduct?.name,
                    item_list_id: payload.itemListId,
                    item_list_name: payload.itemListName,
                    price: getPrices?.price,
                    brand: vendorProduct?.brand?.name,
                    quantity: payload.quantity,
                    index: payload.position
                }
            ],
            cart_id: String(cartId)
        }

        window.dataLayer.push({ ecommerce: null })
        window.dataLayer.push({
            event: marketingEventsConstants.GOOGLE_ANALYTICS_ADD_TO_CART,
            ecommerce: data
        })
    }
}
