import marketingEventsConstants from 'src/constants/marketingEvents'
import { ProductsSearched } from '../interfaces/ProductsSearched'

export const browseSearch = (payload: ProductsSearched) => {
    if (process.env.NEXT_PUBLIC_GA4_KEY) {
        const { term, searchResults, itemListId, itemListName } = payload

        const data = {
            search_term: term,
            number_of_search_results: searchResults,
            item_list_id: itemListId,
            item_list_name: itemListName
        }

        window.dataLayer.push({ ecommerce: null })
        window.dataLayer.push({
            event: marketingEventsConstants.GOOGLE_ANALYTICS_SEARCH,
            ecommerce: data
        })
    }
}
