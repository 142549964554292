import { createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit'
import { ShoppingListItemsResponse } from 'gwy-sdk'
import { InventoryProduct } from 'gwy-sdk/gwy/src/api'
import { shoppingListApi } from '@api/shoppingList'
import { vendorProductsApi } from '@api/vendorProducts'
import { vendorProductInventoryApi } from '@api/vendorProductInventory'
export interface VendorProductInventoryInitialStateInterface {
    requests: Record<string, boolean>
    error?: SerializedError
    vendorProducts: InventoryProduct[]
    vendorProductIdsToFetchInventory: string[]
}

const initialState: VendorProductInventoryInitialStateInterface = {
    requests: {
        getVendorProductInventory: false
    },
    vendorProducts: [],
    vendorProductIdsToFetchInventory: []
}

const vendorProductInventorySlice = createSlice({
    name: 'vendorProductInventory',
    initialState,
    reducers: {
        resetVendorProductIdsToFetchInventory: (state) => {
            state.vendorProductIdsToFetchInventory = []
        }
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            vendorProductInventoryApi.endpoints.getVendorProductInventory.matchFulfilled,
            (state, action) => {
                state.vendorProducts = state.vendorProducts.concat(action.payload.response || [])
            }
        )

        /**
         * We're listening to when getting [products] from catalog finishes and saving the external ids that were fetched
         */
        builder.addMatcher(vendorProductsApi.endpoints.searchVendorProducts.matchFulfilled, (state, action) => {
            state.vendorProductIdsToFetchInventory = action.payload.response.map(
                (vendorProduct) => vendorProduct.externalId
            )
        })

        builder.addMatcher(
            shoppingListApi.endpoints.getItems.matchFulfilled,
            (state, action: PayloadAction<ShoppingListItemsResponse>) => {
                state.vendorProductIdsToFetchInventory = action.payload.response.map((item) => item.product.externalId)
            }
        )
    }
})

export const { resetVendorProductIdsToFetchInventory } = vendorProductInventorySlice.actions
export default vendorProductInventorySlice.reducer
