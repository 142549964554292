import marketingEventsConstants from 'src/constants/marketingEvents'
import { ProductRemoved } from '../interfaces/ProductRemoved'

export const removeFromCart = (payload: ProductRemoved) => {
    if (process.env.NEXT_PUBLIC_GA4_KEY) {
        const { cartId, props, quantity } = payload

        const fullPrice = quantity && Number(props.price) * quantity

        const data = {
            cart_id: cartId,
            currency: marketingEventsConstants.CURRENCY_USD,
            value: Number(fullPrice?.toFixed(2)),
            items: [
                {
                    item_id: props.product?.id,
                    item_name: props.product?.name
                }
            ]
        }

        window.dataLayer.push({ ecommerce: null })
        window.dataLayer.push({
            event: marketingEventsConstants.GOOGLE_ANALYTICS_REMOVE_FROM_CART,
            ecommerce: data
        })
    }
}
