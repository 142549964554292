enum CookieFlags {
    secure = 'secure'
}

interface CookieInterface {
    name: string
    value: string
    path: string
    secure: boolean
    expires?: string
    domain?: string
}

export const setCookie = (cookie: CookieInterface) => {
    const documentCookieArray: string[] = []
    const cookieObj: Record<string, string | boolean> = {
        [cookie.name]: cookie.value,
        path: cookie.path
    }

    if (cookie.domain) {
        cookieObj.domain = cookie.domain
    }

    if (cookie.secure) {
        cookieObj.secure = true
    }

    if (cookie.expires) {
        cookieObj.expires = cookie.expires
    }

    Object.entries(cookieObj).forEach((keyValue) => {
        const [key, value] = keyValue

        switch (key) {
            case CookieFlags.secure:
                documentCookieArray.push(CookieFlags.secure)
                return
            default:
                documentCookieArray.push(`${key}=${value}`)
        }
    })

    document.cookie = documentCookieArray.join(';')
}
