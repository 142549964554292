import { DidYouForgetItemProductWithPrices } from '@components/UpsellItems/UpsellTable'
import { createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit'

export interface UpsellItemsInitialInterface {
    upsells: DidYouForgetItemProductWithPrices[]
    error?: SerializedError
    updateUpsellItems: boolean
    updatedResponse: boolean
}

const initialState: UpsellItemsInitialInterface = {
    upsells: [],
    updateUpsellItems: false,
    updatedResponse: false
}

export const upsellItemsSlice = createSlice({
    name: 'upsellItems',
    initialState,
    reducers: {
        setData: (state, action) => {
            state.upsells = action.payload
        },
        removeUpsellItem: (state, action: PayloadAction<{ id: string }>) => {
            state.upsells = state.upsells.filter((item) => item.id !== action.payload.id)
        },
        updateUpsellItemQuantity: (state, action: PayloadAction<{ id: string; quantity: number }>) => {
            state.updateUpsellItems = true
            state.upsells = state.upsells.map((item) => {
                const price = item.productPrice.prices.find((price) => price.packaging.unit.id === item.quantityUnit.id)
                    ?.packaging.price
                if (item.id === action.payload.id) {
                    return {
                        ...item,
                        quantity: action.payload.quantity,
                        subtotal: price ? action.payload.quantity * price : 0
                    }
                }
                return item
            })
        },
        updateUpsellItemQuantityUnit: (
            state,
            action: PayloadAction<{ id: string; quantityUnitId: string; price: number }>
        ) => {
            state.updateUpsellItems = true
            state.upsells = state.upsells.map((item: DidYouForgetItemProductWithPrices) => {
                if (item.id === action.payload.id) {
                    const priceUnit = item.productPrice.prices.find(
                        (price) => String(price.packaging.unit.id) === action.payload.quantityUnitId
                    )

                    return {
                        ...item,
                        quantityUnit: priceUnit != null ? priceUnit.packaging.unit : item.quantityUnit,
                        subtotal: action.payload.price * item.quantity,
                        price: action.payload.price
                    }
                }

                return item
            })
        }
    }
})

export const { setData, updateUpsellItemQuantity, updateUpsellItemQuantityUnit, removeUpsellItem } =
    upsellItemsSlice.actions

export default upsellItemsSlice.reducer
