import React, { PropsWithChildren } from 'react'
import MobileView from '../MobileView/MobileView'
import { useRouter } from 'next/router'
import pathsConstants from '../../constants/paths'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

const MobileRedirectProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const router = useRouter()
    const { breakpoints } = useTheme()
    const matches = useMediaQuery(() => breakpoints.up('sm'))

    if (
        !matches &&
        router.pathname !== pathsConstants.TERMS_AND_CONDITIONS_URL &&
        router.pathname !== pathsConstants.SIGNUP_INVITATION &&
        router.pathname !== pathsConstants.DEEP_LINK
    ) {
        return <MobileView />
    }

    return <React.Fragment>{children}</React.Fragment>
}

export default MobileRedirectProvider
