import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import marketingEventsConstants from 'src/constants/marketingEvents'

export interface MarketingEventsInitialStateInterface {
    eventOrigin?: string
}

const initialState: MarketingEventsInitialStateInterface = {}

export const marketingEventsSlice = createSlice({
    name: 'marketingEvents',
    initialState,
    reducers: {
        setEventOrigin: (state, action: PayloadAction<marketingEventsConstants>) => {
            state.eventOrigin = action.payload
        }
    }
})

export const { setEventOrigin } = marketingEventsSlice.actions

export default marketingEventsSlice.reducer
