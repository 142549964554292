import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import defaultHeader from 'src/helpers/defaultHeader'
import { getTokenFromAuthCookie } from 'src/helpers/storage/users/getUser'

export const baseApi = createApi({
    reducerPath: 'api/next',
    baseQuery: fetchBaseQuery({
        prepareHeaders: (headers) => {
            const token = getTokenFromAuthCookie()
            const defaultHeaders = defaultHeader()

            headers.set('brand', defaultHeaders.brand)
            headers.set('deviceType', defaultHeaders.deviceType)
            headers.set('deviceId', defaultHeaders.deviceId)
            headers.set('pushId', defaultHeaders.pushId)
            headers.set('gmt', defaultHeaders.gmt)

            if (token) {
                headers.set('authorization', `Bearer ${token}`)
            }

            return headers
        }
    }),
    endpoints: () => ({})
})
