import marketingEventsConstants from 'src/constants/marketingEvents'
import { ProductListViewed } from '../interfaces/ProductListViewed'

export const viewList = (payload: ProductListViewed) => {
    if (process.env.NEXT_PUBLIC_GA4_KEY) {
        const { itemListId, itemListName, items } = payload
        const itemsParsed = items.map((item) => ({ item_id: item.id, item_name: item.name }))

        const data = {
            item_list_id: String(itemListId),
            item_list_name: itemListName,
            items: itemsParsed
        }

        window.dataLayer.push({ ecommerce: null })
        window.dataLayer.push({
            event: marketingEventsConstants.GOOGLE_ANALYTICS_LIST_VIEWED,
            ecommerce: data
        })
    }
}
