import React from 'react'
import { useAppDispatch } from '@hooks'
import { Box, Button, CircularProgress, Snackbar, Typography } from '@mui/material'
import { hideGenericProgressSnackbar } from '@slices/snackbars'

export interface ProgressSnackbarInterface {
    open: boolean
    message?: string
    progress?: number
}

const ProgressSnackbar: React.FC<ProgressSnackbarInterface> = (props) => {
    const dispatch = useAppDispatch()
    const { message, open, progress } = props

    return (
        <Snackbar
            onClose={() => {
                dispatch(hideGenericProgressSnackbar())
            }}
            open={open}
            autoHideDuration={2500}
            message={
                <Box sx={{ display: 'flex', gap: 3 }}>
                    <CircularProgress
                        sx={{ width: '36px !important', height: '36px !important' }}
                        color={'primary'}
                        value={progress || 0}
                        variant={'determinate'}
                    />
                    <Box>
                        <Typography
                            sx={{ color: 'common.white' }}
                            variant={'subtitle1'}
                        >
                            {message}
                        </Typography>
                        <Typography
                            sx={{ color: '#FFFFFF75' }}
                            variant={'body2'}
                        >
                            {progress}%
                        </Typography>
                    </Box>
                    <Button
                        variant={'text'}
                        sx={{ color: 'common.white' }}
                    >
                        Show
                    </Button>
                </Box>
            }
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
            }}
            ClickAwayListenerProps={{
                mouseEvent: false,
                touchEvent: false
            }}
        />
    )
}

export default ProgressSnackbar
