import marketingEventsConstants from 'src/constants/marketingEvents'
import { CartViewed } from '../interfaces/CartViewed'

interface CartItemsInterface {
    item_id: string
    item_name: string
}

export const viewCart = (payload: CartViewed) => {
    if (process.env.NEXT_PUBLIC_GA4_KEY) {
        const { cartId, total, cartsVendors } = payload

        const cartItems: CartItemsInterface[] = []
        cartsVendors?.forEach((cartVendor) => {
            cartVendor.items.map((item) => {
                return cartItems.push({
                    item_id: item.id,
                    item_name: item.product.name
                })
            })
        })

        const data = {
            cart_id: cartId,
            currency: cartId && marketingEventsConstants.CURRENCY_USD,
            value: total,
            items: cartItems
        }

        window.dataLayer.push({ ecommerce: null })
        window.dataLayer.push({
            event: marketingEventsConstants.GOOGLE_ANALYTICS_VIEW_CART,
            ecommerce: data
        })
    }
}
