import { AxiosResponse } from 'axios'
import { SSOApiFactory, Configuration } from 'gwy-sdk'
import defaultHeader from '../helpers/defaultHeader'
import configuration from '../helpers/configuration'

export const logoutService = async (): Promise<AxiosResponse<void>> => {
    const headers = defaultHeader()
    const apiConfiguration: Configuration = configuration()
    const ssoApi = SSOApiFactory(apiConfiguration)
    return await ssoApi.logout(headers.brand, headers.deviceId, headers.deviceType, headers.pushId, headers.gmt)
}
