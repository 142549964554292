import { createSlice } from '@reduxjs/toolkit'

export interface ToastsInitialStateInterface {
    showCookiesNotification: boolean
}

const initialState: ToastsInitialStateInterface = {
    showCookiesNotification: false
}

export const toastsSlice = createSlice({
    name: 'toasts',
    initialState,
    reducers: {
        showCookiesNotificationToast: (state) => {
            state.showCookiesNotification = true
        },
        hideCookiesNotificationToast: (state) => {
            state.showCookiesNotification = false
        }
    }
})

export const { hideCookiesNotificationToast, showCookiesNotificationToast } = toastsSlice.actions

export default toastsSlice.reducer
