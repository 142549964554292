import marketingEventsConstants from 'src/constants/marketingEvents'
import { ProductClicked } from '../interfaces/ProductClicked'
import * as FullStory from '@fullstory/browser'

export const productClicked = (payload: ProductClicked) => {
    if (process.env.NEXT_PUBLIC_FULLSTORY_ORG_ID) {
        const data = {
            item_id_str: payload.itemId,
            item_list_id_str: payload.itemListId,
            item_list_name_str: payload.itemListName,
            external_id_str: payload.externalId,
            vendor_name_str: payload.vendorName,
            position: payload.position,
            currency_id_str: payload.currency,
            name_str: payload.name
        }

        FullStory.event(marketingEventsConstants.FULLSTORY_PRODUCT_CLICKED, data)
    }
}
