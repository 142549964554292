import marketingEventsConstants from 'src/constants/marketingEvents'
import { CheckoutStarted } from '../interfaces/CheckoutStarted'

interface OrderItemsInterface {
    item_id: string
    item_name: string
}

export const beginCheckout = (payload: CheckoutStarted) => {
    if (process.env.NEXT_PUBLIC_GA4_KEY) {
        const { cartId, checkout, customerLocation } = payload

        const orderItems: OrderItemsInterface[] = []
        checkout?.checkoutsVendors.forEach((checkoutVendor) => {
            checkoutVendor.orders.forEach((order) => {
                order.items.map((item) => {
                    return orderItems.push({
                        item_id: item.id,
                        item_name: item.product.name
                    })
                })
            })
        })

        const data = {
            currency: cartId && marketingEventsConstants.CURRENCY_USD,
            value: checkout?.totals.total,
            items: orderItems,
            operator_location: customerLocation?.name,
            cart_id: cartId
        }

        window.dataLayer.push({ ecommerce: null })
        window.dataLayer.push({
            event: marketingEventsConstants.GOOGLE_ANALYTICS_BEGIN_CHECKOUT,
            ecommerce: data
        })
    }
}
