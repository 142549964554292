import { User } from 'gwy-sdk'
import environmentsConstants from '../../../constants/enviroments'

export function getUser(): User | null {
    if (typeof window !== 'undefined') {
        const user = localStorage.getItem('user')
        if (!user || user === 'undefined') {
            return null
        }

        return JSON.parse(user)
    }

    return null
}

export function getTokenFromAuthCookie() {
    let envCookie = ''

    if (typeof document === 'undefined') {
        return ''
    }

    if (process.env.NEXT_PUBLIC_APP_ENV === environmentsConstants.PRODUCTION) {
        envCookie = `morsum_sso=`
    } else {
        envCookie = `morsum_sso-${process.env.NEXT_PUBLIC_APP_ENV}=`
    }

    const cookiesArray = document.cookie.split(';')

    for (let i = 0; i < cookiesArray.length; i++) {
        let cookieElement = cookiesArray[i]
        while (cookieElement.charAt(0) === ' ') {
            cookieElement = cookieElement.substring(1)
        }

        if (cookieElement.indexOf(envCookie) === 0) {
            return cookieElement.substring(envCookie.length, cookieElement.length)
        }
    }

    return ''
}
