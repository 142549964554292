import { baseApi } from './baseApi'
import { CreateCustomerRequest, Customer, LocationResponse } from 'gwy-sdk'

const customersWithTag = baseApi.enhanceEndpoints({ addTagTypes: ['Customer'] })

export const customersApi = customersWithTag.injectEndpoints({
    overrideExisting: true,
    endpoints: (builder) => ({
        getCustomerById: builder.query<Customer, string>({
            query: (customerId) => `customers/${customerId}`,
            transformResponse: (response: { response: Customer }) => response.response,
            providesTags: ['Customer']
        }),
        createCustomer: builder.mutation<Customer, CreateCustomerRequest>({
            query: (request: CreateCustomerRequest) => ({
                url: 'customers',
                method: 'post',
                body: request
            }),
            transformResponse: (response: { response: Customer }) => response.response
        }),
        getLocationInfo: builder.query<LocationResponse, string>({
            query: (locationId: string) => {
                return {
                    url: `customers/me/locations/${locationId}`
                }
            }
        })
    })
})

export const {
    useGetCustomerByIdQuery,
    useLazyGetCustomerByIdQuery,
    useCreateCustomerMutation,
    useLazyGetLocationInfoQuery,
    useGetLocationInfoQuery
} = customersApi
