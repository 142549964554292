import React, { PropsWithChildren } from 'react'
import ErrorSnackbar from '../ErrorSnackbar/ErrorSnackbar'
import { useAppSelector } from '@hooks'
import InfoSnackbar from '../InfoSnackbar/InfoSnackbar'
import ProgressSnackbar from '../ProgressSnackbar/ProgressSnackbar'
import errors from 'src/constants/errors'

const NotificationSnackbarProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const {
        showGenericErrorSnackbar,
        showGenericInfoSnackbar,
        showGenericProgressSnackbar,
        infoMessage,
        errorMessage,
        progressMessage,
        progress
    } = useAppSelector((state) => state.snackbars)

    return (
        <React.Fragment>
            <ErrorSnackbar
                open={showGenericErrorSnackbar}
                message={errorMessage || errors.UNRESPONSIVE_API}
            />
            <InfoSnackbar
                open={showGenericInfoSnackbar}
                message={infoMessage}
            />
            <ProgressSnackbar
                open={showGenericProgressSnackbar}
                progress={progress}
                message={progressMessage}
            />
            {children}
        </React.Fragment>
    )
}

export default NotificationSnackbarProvider
