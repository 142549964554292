import marketingEventsConstants from 'src/constants/marketingEvents'
import * as FullStory from '@fullstory/browser'
import { BatchProductAdded } from '../interfaces/BatchProductAdded'

export const batchProductAdded = (payload: BatchProductAdded) => {
    if (process.env.NEXT_PUBLIC_FULLSTORY_ORG_ID) {
        const { cartId, itemListName, itemListId } = payload

        const data = {
            cart_id_str: cartId,
            item_list_id_str: itemListId,
            item_list_name_str: itemListName
        }

        FullStory.event(marketingEventsConstants.FULLSTORY_BATCH_PRODUCT_ADDED, data)
    }
}
