import { baseApi } from './baseApi'
import { BaseRequestInterface } from '../interfaces/common/BaseRequestInterface'
import {
    AddVendorProductToShoppingListRequest,
    CloneShoppingListRequest,
    CsvExportShoppingListResponse,
    ReorderShoppingListItemsRequest,
    ShoppingListGroupedResponse,
    ShoppingListItemResponse,
    ShoppingListItemsResponse,
    ShoppingListResponse,
    ShoppingListTotal,
    ShoppingsListsResponse,
    SimpleVendorProductsResponse,
    UpdateShoppingListItemRequest,
    VendorProductsPriceRequest,
    ErrorError as GWYError
} from 'gwy-sdk'
import axios from '../helpers/server/axios'
import { getTokenFromAuthCookie } from '../helpers/storage/users/getUser'
import { setUploadProgress } from '@slices/layouts'
// import defaultHeaders from '../helpers/defaultHeader'
import defaultHeader from '../helpers/defaultHeader'

interface GetShoppingListsByCustomerIdInterface extends BaseRequestInterface {
    customerId: string
    customerLocationId: string
}

interface GetItemsInterface {
    id: string
    page?: number
    perPage?: number
    search?: string
    shoppingListSectionId?: string
}

interface PostShoppingListsByCustomerIdInterface extends BaseRequestInterface {
    customerId?: string
}

interface CloneShoppingListsInterface extends CloneShoppingListRequest {
    shoppingListId?: string
}

interface UpdateShoppingListsByCustomerIdInterface {
    shoppingListId?: string
}

interface DeleteShoppingListsByCustomerIdInterface extends BaseRequestInterface {
    customerId: string
    shoppingListId: string
}

interface ReorderingShoppingListItemInterface {
    shoppingListId: string
    request: ReorderShoppingListItemsRequest
}

export interface UpdateMarginInterface {
    shoppingListId: string
    customerLocationId: string
    vendorId: string
    body: VendorProductsPriceRequest
}

const shoppingListWithTag = baseApi.enhanceEndpoints({
    addTagTypes: [
        'getShoppingListsByCustomerId',
        'getOrderGuideStats',
        'getShoppingListSectionByCustomerId',
        'getSummary'
    ]
})

export interface AddVendorProductToShoppingListInterface {
    shoppingListId: string
    body: AddVendorProductToShoppingListRequest
}

export const shoppingListApi = shoppingListWithTag.injectEndpoints({
    overrideExisting: true,
    endpoints: (builder) => ({
        getShoppingListsByCustomerId: builder.query<ShoppingsListsResponse, GetShoppingListsByCustomerIdInterface>({
            query: (request: GetShoppingListsByCustomerIdInterface) => {
                const { customerId, ...rest } = request

                return {
                    url: `customers/${customerId}/shoppings-lists`,
                    params: rest
                }
            },
            providesTags: ['getShoppingListsByCustomerId']
        }),
        updateShoppingListsByCustomerIdProducts: builder.mutation<
            ShoppingsListsResponse,
            GetShoppingListsByCustomerIdInterface
        >({
            query: (request: GetShoppingListsByCustomerIdInterface) => {
                const { customerId, ...rest } = request

                return {
                    url: `customers/${customerId}/shoppings-lists`,
                    params: rest
                }
            },
            invalidatesTags: ['getShoppingListsByCustomerId']
        }),
        createShoppingListsByCustomerId: builder.mutation({
            query: (request: PostShoppingListsByCustomerIdInterface) => {
                const { customerId, ...rest } = request
                return {
                    url: `customers/${customerId}/shoppings-lists`,
                    method: 'post',
                    body: rest
                }
            },
            invalidatesTags: ['getShoppingListsByCustomerId']
        }),
        cloneShoppingLists: builder.mutation({
            query: (request: CloneShoppingListsInterface) => {
                const { shoppingListId, ...rest } = request
                return {
                    url: `shoppings-lists/${shoppingListId}/clone`,
                    method: 'post',
                    body: rest
                }
            },
            invalidatesTags: ['getShoppingListsByCustomerId']
        }),
        getItems: builder.query<ShoppingListItemsResponse, GetItemsInterface>({
            query: (request: GetItemsInterface) => {
                const { id, page, perPage, search, shoppingListSectionId } = request
                return {
                    url: `shoppings-lists/${id}/items`,
                    params: {
                        page,
                        perPage,
                        search,
                        shoppingListSectionId
                    }
                }
            }
        }),
        getGroups: builder.query<ShoppingListGroupedResponse, string>({
            query: (id: string) => {
                return {
                    url: `shoppings-lists/${id}/customers-locations-sync`
                }
            }
        }),
        getSummary: builder.query<ShoppingListResponse, string>({
            query: (id: string) => {
                return {
                    url: `shoppings-lists/${id}`,
                    params: {
                        includes: 'summary'
                    }
                }
            },
            providesTags: ['getSummary']
        }),
        deleteShoppingListByCustomerId: builder.mutation({
            query: (request: DeleteShoppingListsByCustomerIdInterface) => {
                const { customerId, shoppingListId } = request

                return {
                    url: `customers/${customerId}/shoppings-lists/${shoppingListId}`,
                    method: 'delete'
                }
            },
            invalidatesTags: ['getShoppingListsByCustomerId']
        }),
        updateShoppingListsByCustomerId: builder.mutation({
            query: (request: UpdateShoppingListsByCustomerIdInterface) => {
                const { shoppingListId, ...rest } = request

                return {
                    url: `/shoppings-lists/${shoppingListId}`,
                    method: 'PATCH',
                    body: rest
                }
            },
            invalidatesTags: ['getShoppingListsByCustomerId', 'getSummary']
        }),
        reorderingShoppingListIems: builder.mutation({
            query: (reorderRequest: ReorderingShoppingListItemInterface) => {
                const { shoppingListId, request } = reorderRequest
                const { orderedItems, shoppingListSectionId } = request

                return {
                    url: `/shoppings-lists/${shoppingListId}/items-reorder`,
                    method: 'PUT',
                    body: {
                        orderedItems,
                        shoppingListSectionId
                    }
                }
            },
            invalidatesTags: ['getShoppingListsByCustomerId']
        }),
        requestShoppingListCSV: builder.mutation<CsvExportShoppingListResponse, string>({
            query: (shoppingListId) => {
                return {
                    url: `shoppings-lists/${shoppingListId}/csv-export`,
                    method: 'POST'
                }
            }
        }),
        updateShoppingListItem: builder.mutation<
            ShoppingListItemResponse,
            Partial<UpdateShoppingListItemRequest> & {
                shoppingListId: string
                shoppingListItemId: string
            }
        >({
            query: (request) => {
                const { shoppingListId, shoppingListItemId, ...rest } = request
                return {
                    url: `shoppings-lists/${shoppingListId}/items/${shoppingListItemId}`,
                    method: 'PATCH',
                    body: rest
                }
            },
            invalidatesTags: [
                'getShoppingListSectionByCustomerId',
                'getOrderGuideStats',
                'getSummary',
                'getShoppingListsByCustomerId'
            ]
        }),
        getOrderGuideStats: builder.query<ShoppingListTotal, string>({
            query: (shoppingListId: string) => ({
                url: `shoppings-lists/${shoppingListId}/totals`
            }),
            transformResponse: (response: { response: ShoppingListTotal }) => response.response,
            providesTags: ['getOrderGuideStats']
        }),
        updateItemMargin: builder.mutation<SimpleVendorProductsResponse, UpdateMarginInterface>({
            query: (request) => {
                const { vendorId, body, ...params } = request
                return {
                    url: `vendors/${vendorId}/products/prices`,
                    method: 'put',
                    body,
                    params
                }
            },
            transformResponse: (response: { response: SimpleVendorProductsResponse }) => response.response,
            invalidatesTags: ['getOrderGuideStats']
        }),
        deleteShoppingListItems: builder.mutation({
            query: (request) => {
                const { shoppingListId, ...rest } = request

                return {
                    url: `shoppings-lists/${shoppingListId}/items`,
                    method: 'delete',
                    body: rest
                }
            },
            invalidatesTags: ['getSummary']
        }),
        addShoppingListItemsToCart: builder.query({
            query: (request) => {
                const { shoppingListId, ...rest } = request
                return {
                    url: `shoppings-lists/${shoppingListId}/carts`,
                    method: 'post',
                    body: rest
                }
            }
        }),
        addVendorProductToShoppingList: builder.query<
            AddVendorProductToShoppingListRequest,
            AddVendorProductToShoppingListInterface
        >({
            query: (request) => {
                const { shoppingListId, body } = request
                return {
                    url: `shoppings-lists/${shoppingListId}/items`,
                    method: 'post',
                    body
                }
            }
        }),
        resetShoppingListById: builder.mutation({
            query: (shoppingListId: string) => {
                return {
                    url: `/shoppings-lists/${shoppingListId}/reset`,
                    method: 'PATCH'
                }
            }
        }),
        uploadOrderGuide: builder.mutation<ShoppingListResponse, FormData>({
            queryFn: async (arg, api, extraOptions, baseQuery) => {
                const token = getTokenFromAuthCookie()
                const defaultHeaders = defaultHeader()

                try {
                    const response = await axios.post(
                        `${process.env.NEXT_PUBLIC_GWY_API_ENDPOINT}/customers/me/shoppings-lists`,
                        arg,
                        {
                            headers: {
                                brand: defaultHeaders.brand,
                                deviceType: defaultHeaders.deviceType,
                                deviceId: defaultHeaders.deviceId,
                                pushId: defaultHeaders.pushId,
                                gmt: defaultHeaders.gmt,
                                authorization: `Bearer ${token}`
                            },
                            onUploadProgress: (upload) => {
                                const progress = Math.round((100 * upload.loaded) / upload.total)
                                api.dispatch(setUploadProgress(progress))
                            }
                        }
                    )

                    return {
                        data: response.data
                    }
                } catch (e) {
                    const { code, message } = e as GWYError
                    return {
                        error: {
                            status: 500,
                            statusText: code,
                            data: message
                        }
                    }
                }
            },
            invalidatesTags: ['getShoppingListsByCustomerId']
        })
    })
})

export const {
    useUpdateItemMarginMutation,
    useGetShoppingListsByCustomerIdQuery,
    useLazyGetShoppingListsByCustomerIdQuery,
    useUpdateShoppingListsByCustomerIdProductsMutation,
    useCreateShoppingListsByCustomerIdMutation,
    useCloneShoppingListsMutation,
    useRequestShoppingListCSVMutation,
    useGetGroupsQuery,
    useUpdateShoppingListsByCustomerIdMutation,
    useDeleteShoppingListByCustomerIdMutation,
    useReorderingShoppingListIemsMutation,
    useGetItemsQuery,
    useLazyGetItemsQuery,
    useUpdateShoppingListItemMutation,
    useGetOrderGuideStatsQuery,
    useLazyGetOrderGuideStatsQuery,
    useDeleteShoppingListItemsMutation,
    useLazyAddShoppingListItemsToCartQuery,
    useLazyAddVendorProductToShoppingListQuery,
    useResetShoppingListByIdMutation,
    useGetSummaryQuery,
    useLazyGetSummaryQuery,
    useUploadOrderGuideMutation
} = shoppingListApi
