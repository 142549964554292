import { createSlice, SerializedError } from '@reduxjs/toolkit'
import { PaginationResponse, ShoppingList } from 'gwy-sdk'

export interface ShoppingListsInitialStateInterface {
    requests: Record<string, boolean>
    error?: SerializedError
    shoppingLists?: ShoppingList[]
    pagination?: PaginationResponse
    selectedShoppingListId?: string
    selectedShoppingListItemIds: string[]
    search?: string
    shoppingListToClone?: string
}

const initialState: ShoppingListsInitialStateInterface = {
    requests: {
        resetShoppingListById: false,
        addVendorProductToShoppingList: false
    },
    selectedShoppingListItemIds: []
}

export const shoppingListsSlice = createSlice({
    name: 'shoppingLists',
    initialState,
    reducers: {
        setSelectedShoppingListId: (state, action) => {
            state.selectedShoppingListId = action.payload
        },
        setSearch: (state, action) => {
            state.search = action.payload
        },
        setShoppingListToClone: (state, action) => {
            state.shoppingListToClone = action.payload
        }
    }
})

export const { setSelectedShoppingListId, setSearch, setShoppingListToClone } = shoppingListsSlice.actions

export default shoppingListsSlice.reducer
