import { User } from 'gwy-sdk'

export const identifyVisitor = (user: User, token: string) => {
    window.hsConversationsSettings = {
        identificationEmail: user.email,
        identificationToken: token
    }

    if (window.HubSpotConversations !== undefined) {
        window.HubSpotConversations.widget.load()
    }

    setTimeout(() => {
        if (window.HubSpotConversations !== undefined) {
            window.HubSpotConversations.clear({ resetWidget: true })
        }
    }, 1000)
}
