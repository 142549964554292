import { baseApi } from '@api/next/baseApi'
import { CreateVisitorTokenRequest } from '../../interfaces/Hubspot/CreateVisitorTokenRequest'
import { CreateVisitorTokenResponse } from '../../interfaces/Hubspot/CreateVisitorTokenResponse'

export const hubspotApi = baseApi.injectEndpoints({
    overrideExisting: true,
    endpoints: (builder) => ({
        createVisitorToken: builder.mutation<CreateVisitorTokenResponse, CreateVisitorTokenRequest>({
            query: (request) => {
                return {
                    method: 'post',
                    url: 'api/hubspot/visitors/create',
                    body: request
                }
            }
        })
    })
})

export const { useCreateVisitorTokenMutation } = hubspotApi
