import axios from 'axios'
import defaultHeader from '../defaultHeader'

const defaultHeaders = defaultHeader()

const instance = axios.create({
    baseURL: process.env.NEXT_PUBLIC_GWY_API_ENDPOINT,
    headers: {
        brand: defaultHeaders.brand,
        deviceType: defaultHeaders.deviceType,
        deviceId: defaultHeaders.deviceId,
        pushId: defaultHeaders.pushId,
        gmt: defaultHeaders.gmt
    }
})

instance.interceptors.request.use(
    (config) => {
        // @ts-expect-error
        config.time = { startTime: new Date() }
        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)

instance.interceptors.response.use(
    (response) => {
        const endTime = new Date()
        // @ts-expect-error
        const duration = endTime - response.config.time.startTime

        console.info({
            baseUrl: response.config.baseURL,
            url: response.config.url,
            method: response.config.method,
            status: response.status,
            statusText: response.statusText,
            duration: `${duration}ms`
        })

        return response
    },
    (error) => {
        console.error({
            baseUrl: error.config.baseURL,
            url: error.config.url,
            method: error.config.method,
            response: error.response,
            data: error.response.data
        })

        return Promise.reject(error.response.data.error)
    }
)

export default instance
