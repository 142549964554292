import { createSlice } from '@reduxjs/toolkit'
import { Vendor } from 'gwy-sdk'

export interface VendorInitialStateInterface {
    vendor?: Vendor
}

const initialState: VendorInitialStateInterface = {
    vendor: undefined
}

export const vendorSlice = createSlice({
    name: 'vendor',
    initialState,
    reducers: {
        setVendor: (state, action) => {
            state.vendor = action.payload
        }
    }
})

export const { setVendor } = vendorSlice.actions

export default vendorSlice.reducer
