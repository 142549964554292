import React, { PropsWithChildren } from 'react'
import { useRouter } from 'next/router'
import { useUser } from '@hooks/users/useUser'
import privateRoutes from '../../constants/privateRoutes'
import { useDeleteAllCookies } from '@hooks/cookies/useDeleteAllCookies'
import { useLazyCheckSessionStatusQuery } from '@api/auth'
import { getTokenFromAuthCookie } from '../../helpers/storage/users/getUser'
import { useIdentify } from '@hooks/events/useIdentify'

const isBrowser = () => typeof window !== 'undefined'

const AuthProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const router = useRouter()
    const user = useUser()
    const [checkSessionStatus, { data, error }] = useLazyCheckSessionStatusQuery()
    const token = getTokenFromAuthCookie()
    const { deleteAllCookies } = useDeleteAllCookies()
    const { identify } = useIdentify()

    React.useEffect(() => {
        if (isBrowser() && token && router.pathname === '/') {
            checkSessionStatus()
        }
    }, [isBrowser(), token, router.pathname])

    React.useEffect(() => {
        if (data) {
            identify(data)
            router.push('/dashboard')
        }

        if (error) {
            sessionStorage.clear()
            localStorage.clear()
            deleteAllCookies()
        }
    }, [data, error])

    if (isBrowser() && privateRoutes.includes(router.pathname)) {
        function storageChange(e: StorageEvent) {
            if (e.key === 'customerLocation') {
                router.push('/dashboard')
            }
            if (e.key === null) {
                router.replace('/')
            }
        }

        window.addEventListener('storage', storageChange, false)

        if (!user) {
            sessionStorage.clear()
            localStorage.clear()
            deleteAllCookies()
            router.push('/')
        }
    }

    return <React.Fragment>{children}</React.Fragment>
}

export default AuthProvider
