import React from 'react'
import { Provider } from 'react-redux'
import { store } from '../src/helpers/store'
import { LocalizationProvider } from '@mui/x-date-pickers'
import NotificationSnackbarProvider from '@components/Providers/NotificationSnackbarProvider'
import MobileRedirectProvider from '@components/Providers/MobileRedirectProvider'
import { ErrorBoundary } from 'react-error-boundary'
import FallbackError from '@components/FallbackError/FallbackError'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { AppProps } from 'next/app'
import 'react-image-gallery/styles/css/image-gallery.css'
import createEmotionCache from '../src/helpers/createEmotionCache'
import { CacheProvider, EmotionCache } from '@emotion/react'
import { Box, useMediaQuery, useTheme } from '@mui/material'
import AuthProvider from '@components/Providers/AuthProvider'
import { LicenseInfo } from '@mui/x-license-pro'
import * as FullStory from '@fullstory/browser'
import GoogleAnalytics from '@components/Providers/GoogleAnalytics'
import GoogleTagManager from '@components/Providers/GoogleTagManager'
import WhiteLabelThemeProvider from '@components/Providers/WhiteLabelThemeProvider'
import { getUser } from '../src/helpers/storage/users/getUser'
import { getApps, initializeApp } from 'firebase/app'
import { setApplicationVersion } from '../src/lib/events/newRelic'

const onErrorHandler = (
    error: Error,
    info: {
        componentStack: string
    }
) => {
    console.error(error)
    console.info(info)

    const user = getUser()

    window.newrelic.noticeError(error, {
        isCatOfDeath: true,
        userUuid: user?.uuid
    })
}

interface MyAppProps extends AppProps {
    emotionCache?: EmotionCache
}

const clientSideEmotionCache = createEmotionCache()

// @ts-expect-error
LicenseInfo.setLicenseKey(process.env.NEXT_PUBLIC_MUI_KEY)

function MyApp({ Component, pageProps, emotionCache = clientSideEmotionCache }: MyAppProps) {
    /**
     * This piece of code is in here to prevent FOUC
     * https://github.com/vercel/next.js/issues/18769
     */
    const [mounted, setMounted] = React.useState(false)
    React.useEffect(() => {
        setMounted(true)
    }, [])

    React.useEffect(() => {
        if (process.env.NEXT_PUBLIC_FULLSTORY_ORG_ID) {
            FullStory.init({ orgId: process.env.NEXT_PUBLIC_FULLSTORY_ORG_ID })
        }

        if (!getApps().length) {
            const firebaseConfig = {
                apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
                authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
                projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
                storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
                messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDING_ID,
                appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
                measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID
            }

            initializeApp(firebaseConfig)
        }

        if (process.env.NEXT_PUBLIC_APP_VERSION) {
            setApplicationVersion(process.env.NEXT_PUBLIC_APP_VERSION)
        }
    }, [])

    const theme = useTheme()
    const isSm = useMediaQuery(theme.breakpoints.down('sm'))

    return (
        <Box style={{ visibility: !mounted ? 'hidden' : 'visible', display: isSm ? 'grid' : 'block' }}>
            <GoogleAnalytics />
            <GoogleTagManager />
            <CacheProvider value={emotionCache}>
                <title>Anchovi</title>
                <Provider store={store}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <WhiteLabelThemeProvider>
                            <ErrorBoundary
                                FallbackComponent={FallbackError}
                                onError={onErrorHandler}
                            >
                                <NotificationSnackbarProvider>
                                    <MobileRedirectProvider>
                                        <AuthProvider>
                                            <Component {...pageProps} />
                                        </AuthProvider>
                                    </MobileRedirectProvider>
                                </NotificationSnackbarProvider>{' '}
                            </ErrorBoundary>
                        </WhiteLabelThemeProvider>
                    </LocalizationProvider>
                </Provider>
            </CacheProvider>
        </Box>
    )
}

export default MyApp
