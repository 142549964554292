import { PayloadAction, createSlice } from '@reduxjs/toolkit'

export interface DialogsInitialStateInterface {
    showCreateShoppingListDialog: boolean
    showCloneShoppingListDialog: boolean
    showEditShoppingListDialog: boolean
    showAllShoppingListsDialog: boolean
    showLockShoppingListsDialog: boolean
    showShareShoppingListsDialog: boolean
    showAllboardingProductImportDialog: boolean
    showAllboardingCustomerImportDialog: boolean
    showItemsNotAddedToCartDialog: boolean
    metaVendorProductsNotAdded?: any
    showClearCartDialog: boolean
    showReviewDeliveryDatesInfoBox: boolean
    showRemoveShoppingListDialog: boolean
    showRemoveSectionDialog: boolean
    showEndlessAislePaymentConfirmationModal: boolean
    showCutOffModal: boolean
    showInstructionsModal: boolean
    showMenuDrawer: boolean
    navigationExpanded: boolean
    orderInstructionsModalData?: { id: string; notes: string }
    unableToProcessPaymentModal: boolean
    showStripePaymentMethodModal: boolean
    showUploadOrderGuideDialog: boolean
    showUploadOrderGuideErrorDialog: boolean
    showBroadcastMessageErrorDialog: boolean
}

const initialState: DialogsInitialStateInterface = {
    showCreateShoppingListDialog: false,
    showCloneShoppingListDialog: false,
    showEditShoppingListDialog: false,
    showAllShoppingListsDialog: false,
    showLockShoppingListsDialog: false,
    showShareShoppingListsDialog: false,
    showAllboardingProductImportDialog: false,
    showAllboardingCustomerImportDialog: false,
    showItemsNotAddedToCartDialog: false,
    showClearCartDialog: false,
    showReviewDeliveryDatesInfoBox: false,
    showRemoveShoppingListDialog: false,
    showRemoveSectionDialog: false,
    showEndlessAislePaymentConfirmationModal: false,
    showCutOffModal: false,
    showInstructionsModal: false,
    showMenuDrawer: false,
    navigationExpanded: true,
    unableToProcessPaymentModal: false,
    showStripePaymentMethodModal: false,
    showUploadOrderGuideDialog: false,
    showUploadOrderGuideErrorDialog: false,
    showBroadcastMessageErrorDialog: false
}

export const dialogsSlice = createSlice({
    name: 'dialogs',
    initialState,
    reducers: {
        setShowCutOffModal: (state, action) => {
            state.showCutOffModal = action.payload
        },
        setShowMenuDrawer: (state, action) => {
            state.showMenuDrawer = action.payload
        },
        setNavigationExpanded: (state, action) => {
            state.navigationExpanded = action.payload
        },
        setShowLockShoppingListsDialog: (state, action) => {
            state.showLockShoppingListsDialog = action.payload
        },
        setShowShareShoppingListsDialog: (state, action) => {
            state.showShareShoppingListsDialog = action.payload
        },
        showAllboardingProductImportDialog: (state) => {
            state.showAllboardingProductImportDialog = true
        },
        hideAllboardingProductImportDialog: (state) => {
            state.showAllboardingProductImportDialog = false
        },
        showAllboardingCustomerImportDialog: (state) => {
            state.showAllboardingCustomerImportDialog = true
        },
        showItemsNotAddedToCartDialog: (state, action) => {
            state.showItemsNotAddedToCartDialog = true
            state.metaVendorProductsNotAdded = action.payload
        },
        hideAllboardingCustomerImportDialog: (state) => {
            state.showAllboardingCustomerImportDialog = false
        },
        showCreateShoppingListDialog: (state) => {
            state.showCreateShoppingListDialog = true
        },
        hideCreateShoppingListDialog: (state) => {
            state.showCreateShoppingListDialog = false
        },
        showCloneShoppingListDialog: (state) => {
            state.showCloneShoppingListDialog = true
        },
        hideCloneShoppingListDialog: (state) => {
            state.showCloneShoppingListDialog = false
        },
        showEditShoppingListDialog: (state) => {
            state.showEditShoppingListDialog = true
        },
        hideEditShoppingListDialog: (state) => {
            state.showEditShoppingListDialog = false
        },
        showAllShoppingListsDialog: (state) => {
            state.showAllShoppingListsDialog = true
        },
        hideAllShoppingListsDialog: (state) => {
            state.showAllShoppingListsDialog = false
        },
        hideItemsNotAddedToCartDialog: (state) => {
            state.showItemsNotAddedToCartDialog = false
            state.metaVendorProductsNotAdded = undefined
        },
        showRemoveShoppingListDialog: (state) => {
            state.showRemoveShoppingListDialog = true
        },
        hideRemoveShoppingListDialog: (state) => {
            state.showRemoveShoppingListDialog = false
        },
        showRemoveSectionDialog: (state) => {
            state.showRemoveSectionDialog = true
        },
        hideRemoveSectionDialog: (state) => {
            state.showRemoveSectionDialog = false
        },
        showClearCartDialog: (state) => {
            state.showClearCartDialog = true
        },
        hideClearCartDialog: (state) => {
            state.showClearCartDialog = false
        },
        showReviewDeliveryDatesInfoBox: (state) => {
            state.showReviewDeliveryDatesInfoBox = true
        },
        hideReviewDeliveryDatesInfoBox: (state) => {
            state.showReviewDeliveryDatesInfoBox = false
        },
        showEndlessAislePaymentConfirmationModal: (state) => {
            state.showEndlessAislePaymentConfirmationModal = true
        },
        hideEndlessAislePaymentConfirmationModal: (state) => {
            state.showEndlessAislePaymentConfirmationModal = false
        },
        showInstructionsModal: (state, action: PayloadAction<{ id: string; notes: string }>) => {
            state.showInstructionsModal = true
            state.orderInstructionsModalData = action.payload
        },
        hideShowInstructionsModal: (state) => {
            state.showInstructionsModal = false
            state.orderInstructionsModalData = undefined
        },
        showUnableToProcessPaymentModal: (state) => {
            state.unableToProcessPaymentModal = true
        },
        hideUnableToProcessPaymentModal: (state) => {
            state.unableToProcessPaymentModal = false
        },
        showStripePaymentMethodModal: (state) => {
            state.showStripePaymentMethodModal = true
        },
        hideStripePaymentMethodModal: (state) => {
            state.showStripePaymentMethodModal = false
        },
        showUploadOrderGuideDialog: (state) => {
            state.showUploadOrderGuideDialog = true
        },
        hideUploadOrderGuideDialog: (state) => {
            state.showUploadOrderGuideDialog = false
        },
        showUploadOrderGuideErrorDialog: (state) => {
            state.showUploadOrderGuideErrorDialog = true
        },
        hideUploadOrderGuideErrorDialog: (state) => {
            state.showUploadOrderGuideErrorDialog = false
        },
        showBroadcastMessageErrorDialog: (state) => {
            state.showBroadcastMessageErrorDialog = true
        },
        hideBroadcastMessageErrorDialog: (state) => {
            state.showBroadcastMessageErrorDialog = false
        }
    }
})

export const {
    showCloneShoppingListDialog,
    hideCloneShoppingListDialog,
    showCreateShoppingListDialog,
    showEditShoppingListDialog,
    showAllShoppingListsDialog,
    setShowLockShoppingListsDialog,
    setShowShareShoppingListsDialog,
    showAllboardingProductImportDialog,
    hideCreateShoppingListDialog,
    hideEditShoppingListDialog,
    hideAllShoppingListsDialog,
    showAllboardingCustomerImportDialog,
    hideAllboardingCustomerImportDialog,
    hideAllboardingProductImportDialog,
    showItemsNotAddedToCartDialog,
    hideItemsNotAddedToCartDialog,
    showClearCartDialog,
    hideClearCartDialog,
    showReviewDeliveryDatesInfoBox,
    hideReviewDeliveryDatesInfoBox,
    showRemoveShoppingListDialog,
    hideRemoveShoppingListDialog,
    showRemoveSectionDialog,
    hideRemoveSectionDialog,
    showEndlessAislePaymentConfirmationModal,
    hideEndlessAislePaymentConfirmationModal,
    setShowCutOffModal,
    showInstructionsModal,
    hideShowInstructionsModal,
    setShowMenuDrawer,
    showUnableToProcessPaymentModal,
    hideUnableToProcessPaymentModal,
    setNavigationExpanded,
    showStripePaymentMethodModal,
    hideStripePaymentMethodModal,
    hideUploadOrderGuideDialog,
    showUploadOrderGuideDialog,
    showUploadOrderGuideErrorDialog,
    hideUploadOrderGuideErrorDialog,
    showBroadcastMessageErrorDialog,
    hideBroadcastMessageErrorDialog
} = dialogsSlice.actions

export default dialogsSlice.reducer
