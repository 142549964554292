import marketingEventsConstants from 'src/constants/marketingEvents'
import { ProductListFiltered } from '../interfaces/ProductListFiltered'
import * as FullStory from '@fullstory/browser'

export const productListFiltered = (payload: ProductListFiltered) => {
    if (process.env.NEXT_PUBLIC_FULLSTORY_ORG_ID) {
        const { itemListId, itemListName, filterType, filter } = payload

        const data = {
            item_list_id_str: itemListId,
            item_list_name_str: itemListName,
            filters_type_str: filterType,
            filters_value_strs: [filter]
        }

        FullStory.event(marketingEventsConstants.FULLSTORY_PRODUCT_LIST_FILTERED, data)
    }
}
