import { createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit'
import { PaginationResponse, VendorProduct } from 'gwy-sdk'
import { VendorProductResponseMetaInterface } from '../interfaces/VendorProductsResponseWithMetaInterface'
import vendorProductsConstants from '../constants/vendorsProducts'

export interface VendorProductsInitialStateInterface {
    error?: SerializedError
    vendorProducts: VendorProduct[]
    pagination?: PaginationResponse
    originalMetaData?: VendorProductResponseMetaInterface
    meta?: VendorProductResponseMetaInterface
    search?: string
    selectedBrandIds: string[]
    selectedAttributesIds: string[]
    selectedDietariesIds: string[]
    selectedAvailabilityFlagsIds: string[]
    selectedStorageCodesIds: string[]
    selectedPackSizeUnitsIds: string[]
    selectedCategoryIds: string[]
    selectedShoppingListIds: string[]
    selectedSortingOptionId: string
    lastPurchased?: {
        value: string
        label: string
    }
    selectedVendorProductId?: string
}

const initialState: VendorProductsInitialStateInterface = {
    selectedBrandIds: [],
    selectedAttributesIds: [],
    selectedDietariesIds: [],
    selectedAvailabilityFlagsIds: [],
    selectedStorageCodesIds: [],
    selectedPackSizeUnitsIds: [],
    selectedCategoryIds: [],
    selectedShoppingListIds: [],
    selectedSortingOptionId: vendorProductsConstants.SORTING_OPTION_RECENTLY_ADDED,
    vendorProducts: [],
    search: undefined
}

export const vendorProductsSlice = createSlice({
    name: 'vendorsProducts',
    initialState,
    reducers: {
        bulkToggleSelectedBrandIds: (state, action: PayloadAction<string[]>) => {
            state.selectedBrandIds = action.payload
        },
        bulkToggleSelectedAttributesIds: (state, action: PayloadAction<string[]>) => {
            state.selectedAttributesIds = action.payload
        },
        bulkToggleSelectedDietariesIds: (state, action: PayloadAction<string[]>) => {
            state.selectedDietariesIds = action.payload
        },
        bulkToggleSelectedPackSizeUnitsIds: (state, action: PayloadAction<string[]>) => {
            state.selectedPackSizeUnitsIds = action.payload
        },
        bulkToggleSelectedAvailabilityFlagsIds: (state, action: PayloadAction<string[]>) => {
            state.selectedAvailabilityFlagsIds = action.payload
        },
        bulkToggleSelectedStorageCodesIds: (state, action: PayloadAction<string[]>) => {
            state.selectedStorageCodesIds = action.payload
        },
        bulkToggleSelectedCategoryIds: (state, action: PayloadAction<string[]>) => {
            state.selectedCategoryIds = action.payload
        },
        bulkToggleSelectedShoppingListIds: (state, action: PayloadAction<string[]>) => {
            state.selectedShoppingListIds = action.payload
        },
        toggleSelectedBrandIds: (state, action: PayloadAction<string>) => {
            const index = state.selectedBrandIds.indexOf(action.payload)

            if (index === -1) {
                state.selectedBrandIds.push(action.payload)
            } else {
                state.selectedBrandIds.splice(index, 1)
            }
        },
        toggleSelectedAttributesIds: (state, action: PayloadAction<string>) => {
            const index = state.selectedAttributesIds.indexOf(action.payload)

            if (index === -1) {
                state.selectedAttributesIds.push(action.payload)
            } else {
                state.selectedAttributesIds.splice(index, 1)
            }
        },
        toggleSelectedDietariesIds: (state, action: PayloadAction<string>) => {
            const index = state.selectedDietariesIds.indexOf(action.payload)

            if (index === -1) {
                state.selectedDietariesIds.push(action.payload)
            } else {
                state.selectedDietariesIds.splice(index, 1)
            }
        },
        toggleSelectedPackSizeUnitsIds: (state, action: PayloadAction<string>) => {
            const index = state.selectedPackSizeUnitsIds.indexOf(action.payload)

            if (index === -1) {
                state.selectedPackSizeUnitsIds.push(action.payload)
            } else {
                state.selectedPackSizeUnitsIds.splice(index, 1)
            }
        },
        toggleSelectedAvailabilityFlagsIds: (state, action: PayloadAction<string>) => {
            const index = state.selectedAvailabilityFlagsIds.indexOf(action.payload)

            if (index === -1) {
                state.selectedAvailabilityFlagsIds.push(action.payload)
            } else {
                state.selectedAvailabilityFlagsIds.splice(index, 1)
            }
        },
        toggleSelectedStorageCodesIds: (state, action: PayloadAction<string>) => {
            const index = state.selectedStorageCodesIds.indexOf(action.payload)

            if (index === -1) {
                state.selectedStorageCodesIds.push(action.payload)
            } else {
                state.selectedStorageCodesIds.splice(index, 1)
            }
        },
        toggleSelectedCategoryIds: (state, action: PayloadAction<string>) => {
            const index = state.selectedCategoryIds.indexOf(action.payload)

            if (index === -1) {
                state.selectedCategoryIds.push(action.payload)
            } else {
                state.selectedCategoryIds.splice(index, 1)
            }
        },
        toggleSelectedShoppingListIds: (state, action: PayloadAction<string>) => {
            const index = state.selectedShoppingListIds.indexOf(action.payload)

            if (index === -1) {
                state.selectedShoppingListIds.push(action.payload)
            } else {
                state.selectedShoppingListIds.splice(index, 1)
            }
        },
        setLastPurchased: (state, action: PayloadAction<{ value: string; label: string } | undefined>) => {
            state.lastPurchased = action.payload
        },
        setSelectedSortingOptionId: (state, action: PayloadAction<string>) => {
            state.selectedSortingOptionId = action.payload
        },
        setSearch: (state, action: PayloadAction<string | undefined>) => {
            state.search = action.payload
        },
        setSelectedVendorProductId: (state, action: PayloadAction<string>) => {
            state.selectedVendorProductId = action.payload
        },
        clearSelectedVendorProductId: (state) => {
            state.selectedVendorProductId = undefined
        },
        clearFilters: (state) => {
            state.selectedBrandIds = []
            state.selectedDietariesIds = []
            state.selectedAvailabilityFlagsIds = []
            state.selectedAttributesIds = []
            state.selectedStorageCodesIds = []
            state.selectedPackSizeUnitsIds = []
            state.selectedCategoryIds = []
            state.selectedShoppingListIds = []
            state.lastPurchased = undefined
            state.search = undefined
        }
    }
})

export const {
    setSearch,
    bulkToggleSelectedBrandIds,
    bulkToggleSelectedAttributesIds,
    bulkToggleSelectedDietariesIds,
    bulkToggleSelectedPackSizeUnitsIds,
    bulkToggleSelectedAvailabilityFlagsIds,
    bulkToggleSelectedStorageCodesIds,
    toggleSelectedBrandIds,
    toggleSelectedAttributesIds,
    toggleSelectedDietariesIds,
    toggleSelectedPackSizeUnitsIds,
    toggleSelectedAvailabilityFlagsIds,
    toggleSelectedStorageCodesIds,
    bulkToggleSelectedCategoryIds,
    toggleSelectedCategoryIds,
    bulkToggleSelectedShoppingListIds,
    toggleSelectedShoppingListIds,
    setSelectedSortingOptionId,
    setSelectedVendorProductId,
    setLastPurchased,
    clearSelectedVendorProductId,
    clearFilters
} = vendorProductsSlice.actions

export default vendorProductsSlice.reducer
