import marketingEventsConstants from 'src/constants/marketingEvents'
import * as FullStory from '@fullstory/browser'
import { OrderGuideShared } from '../interfaces/OrderGuideShared'

export const orderGuideShared = (payload: OrderGuideShared) => {
    if (process.env.NEXT_PUBLIC_FULLSTORY_ORG_ID) {
        const {
            itemListName,
            itemListId,
            vendorName,
            operatorLocationId,
            operatorLocationName,
            operatorLocationIdsShared
        } = payload

        const data = {
            item_list_id_str: String(itemListId),
            item_list_name_str: itemListName,
            vendor_name_str: vendorName,
            operator_location_id_str: String(operatorLocationId),
            operator_location_name_str: operatorLocationName,
            operator_location_ids_shared_strs: operatorLocationIdsShared
        }

        FullStory.event(marketingEventsConstants.FULLSTORY_ORDER_GUIDE_SHARED, data)
    }
}
