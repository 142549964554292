import marketingEventsConstants from 'src/constants/marketingEvents'
import { ProductRemoved } from '../interfaces/ProductRemoved'
import * as FullStory from '@fullstory/browser'

export const productRemoved = (payload: ProductRemoved) => {
    if (process.env.NEXT_PUBLIC_FULLSTORY_ORG_ID) {
        const { cartId, props, itemListName, quantity } = payload

        const valueReal = quantity && Number(props.price) * quantity

        const data = {
            cart_id_str: cartId,
            item_id_str: String(props.product?.vendor.id),
            centralization_id_str: null,
            external_id_str: props.product?.externalId,
            vendor_name_str: props.product?.vendor.name,
            item_list_id_str: cartId,
            item_list_name_str: itemListName,
            brand_str: props.product?.brand?.name,
            name_str: props.product?.name,
            currency_str: marketingEventsConstants.CURRENCY_USD,
            value_real: Number(valueReal?.toFixed(2)),
            price_real: props.price,
            item_category_5_str: props.quantityUnit?.name,
            quantity_int: quantity
        }

        FullStory.event(marketingEventsConstants.FULLSTORY_PRODUCT_REMOVED, data)
    }
}
