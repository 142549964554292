import * as FullStory from '@fullstory/browser'
import marketingEventsConstants from 'src/constants/marketingEvents'
import { InviteNewUser } from '../interfaces/InviteNewUser'

export const inviteNewUser = (payload: InviteNewUser) => {
    if (process.env.NEXT_PUBLIC_FULLSTORY_ORG_ID) {
        const data = {
            invite_user_str: payload.inviteUser,
            operator_location_str: payload.operatorLocation
        }

        FullStory.event(marketingEventsConstants.FULLSTORY_INVITE_USER, data)
    }
}
