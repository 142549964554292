import { baseApi } from './baseApi'
import { BaseRequestInterface } from '../interfaces/common/BaseRequestInterface'
import { ShoppingListSectionsResponse } from 'gwy-sdk'

interface GetShoppingListsSectionsInterface extends BaseRequestInterface {
    customerId: string
    shoppingListId: string
}

interface CreateShoppingListSectionByCustomerId {
    customerId: string
    shoppingListId: string
    name: string
}
interface DeleteShoppingListSectionByCustomerId {
    customerId: string
    shoppingListSectionId: string
}

const shoppingListSectionWithTag = baseApi.enhanceEndpoints({
    addTagTypes: ['getShoppingListSectionByCustomerId']
})

export const shoppingListSectionsApi = shoppingListSectionWithTag.injectEndpoints({
    overrideExisting: true,
    endpoints: (builder) => ({
        getShoppingListSectionByCustomerId: builder.query<
            ShoppingListSectionsResponse,
            GetShoppingListsSectionsInterface
        >({
            query: (request) => {
                const { customerId, shoppingListId } = request

                return {
                    url: `customers/${customerId}/shoppings-lists/${shoppingListId}/shoppings-lists-sections`,
                    params: {
                        includes: 'itemsOrder'
                    }
                }
            },
            providesTags: ['getShoppingListSectionByCustomerId']
        }),
        createShoppingListSectionByCustomerId: builder.mutation({
            query: (request: CreateShoppingListSectionByCustomerId) => {
                const { customerId, shoppingListId, name } = request
                return {
                    url: `customers/${customerId}/shoppings-lists-sections`,
                    method: 'POST',
                    body: {
                        name,
                        shoppingListId
                    }
                }
            },
            invalidatesTags: ['getShoppingListSectionByCustomerId']
        }),
        deleteShoppingListSectionByCustomerId: builder.mutation({
            query: (request: DeleteShoppingListSectionByCustomerId) => {
                const { customerId, shoppingListSectionId } = request

                return {
                    url: `customers/${customerId}/shoppings-lists-sections/${shoppingListSectionId}`,
                    method: 'delete'
                }
            },
            invalidatesTags: ['getShoppingListSectionByCustomerId']
        })
    })
})

export const {
    useGetShoppingListSectionByCustomerIdQuery,
    useCreateShoppingListSectionByCustomerIdMutation,
    useDeleteShoppingListSectionByCustomerIdMutation
} = shoppingListSectionsApi
