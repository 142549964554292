import { CartItem, CartVendor } from 'gwy-sdk'
import { createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit'
import { cartApi } from '@api/cart'
import { useGetCustomerLocation } from '@hooks/customerLocation/useGetCustomerLocation'

export interface CartItemsInitialStateInterface {
    vendors: CartVendor[]
    error?: SerializedError
    itemsBeingAdded: string[]
    updateCart: boolean
    updatedResponse: boolean
}

const initialState: CartItemsInitialStateInterface = {
    itemsBeingAdded: [],
    vendors: [],
    updateCart: false,
    updatedResponse: false
}

export const cartItemsSlice = createSlice({
    name: 'cartVendors',
    initialState,
    reducers: {
        addItemBeingAdded: (state, action) => {
            const id = action.payload
            state.itemsBeingAdded.push(id)
        },
        removeItemBeingAdded: (state, action) => {
            const id = action.payload
            state.itemsBeingAdded = state.itemsBeingAdded.filter((item) => item !== id)
        },
        resetCartItem: (state, action: PayloadAction<{ id: string; margin: number; price: number }>) => {
            state.updateCart = true
            state.vendors = state.vendors.map((vendor) => {
                return {
                    ...vendor,
                    items: vendor.items.map((item) => {
                        if (item.id === action.payload.id) {
                            return {
                                ...item,
                                dsrMargin: action.payload.margin,
                                subtotal: action.payload.price * item.quantity,
                                forwardPrice: false,
                                product: {
                                    ...item.product,
                                    prices: item.product.prices.map((price) => {
                                        if (String(price.packaging.unit.id) === String(item.quantityUnit.id)) {
                                            return {
                                                ...price,
                                                price: action.payload.price,
                                                packaging: {
                                                    ...price.packaging,
                                                    price: action.payload.price
                                                }
                                            }
                                        }

                                        return price
                                    })
                                }
                            }
                        }

                        return item
                    })
                }
            })
        },
        updateCartItemQuantityUnit: (
            state,
            action: PayloadAction<{ id: string; quantityUnitId: string; margin: number; price: number }>
        ) => {
            state.updateCart = true
            state.vendors = state.vendors.map((vendor) => {
                return {
                    ...vendor,
                    items: vendor.items.map((item: CartItem) => {
                        if (item.id === action.payload.id) {
                            const priceUnit = item.product.prices.find(
                                (price) => String(price.packaging.unit.id) === action.payload.quantityUnitId
                            )

                            return {
                                ...item,
                                forwardPrice: false,
                                dsrMargin: action.payload.margin ?? item.dsrMargin,
                                quantityUnit: priceUnit != null ? priceUnit.packaging.unit : item.quantityUnit,
                                subtotal: action.payload.price * item.quantity,
                                price: action.payload.price
                            }
                        }

                        return item
                    })
                }
            })
        },
        updateCartItemQuantity: (state, action: PayloadAction<{ id: string; quantity: number }>) => {
            state.updateCart = true
            state.vendors = state.vendors.map((vendor) => {
                return {
                    ...vendor,
                    items: vendor.items.map((item: CartItem) => {
                        if (item.id === action.payload.id) {
                            const price = item.product.prices.find(
                                (price) => price.packaging.unit.id === item.quantityUnit.id
                            )?.packaging.price

                            return {
                                ...item,
                                quantity: action.payload.quantity,
                                subtotal: price ? action.payload.quantity * price : 0
                            }
                        }

                        return item
                    })
                }
            })
        },
        updateCartItemMargin: (state, action: PayloadAction<{ id: string; margin: number; price: number }>) => {
            state.updateCart = true
            state.vendors = state.vendors.map((vendor) => {
                return {
                    ...vendor,
                    items: vendor.items.map((item: CartItem) => {
                        if (item.id === action.payload.id) {
                            return {
                                ...item,
                                dsrMargin: action.payload.margin,
                                subtotal: action.payload.price * item.quantity,
                                forwardPrice: true,
                                product: {
                                    ...item.product,
                                    prices: item.product.prices.map((price) => {
                                        if (String(price.packaging.unit.id) === String(item.quantityUnit.id)) {
                                            return {
                                                ...price,
                                                price: action.payload.price,
                                                packaging: {
                                                    ...price.packaging,
                                                    price: action.payload.price
                                                }
                                            }
                                        }

                                        return price
                                    })
                                }
                            }
                        }

                        return item
                    })
                }
            })
        },
        removeCartItem: (state, action: PayloadAction<{ cartItemId: string }>) => {
            state.updateCart = true
            state.vendors = state.vendors.map((vendor) => {
                return {
                    ...vendor,
                    items: vendor.items.filter((item: CartItem) => {
                        return item.id !== action.payload.cartItemId
                    })
                }
            })
        },
        removeAllCartItems: (state) => {
            state.updateCart = true
            state.vendors = state.vendors.map((vendor) => {
                return {
                    ...vendor,
                    items: []
                }
            })
        },
        setVendors: (state, action) => {
            state.vendors = action.payload
        },
        updateVendors: (state, action) => {
            const { newVendors, productId, overwrite } = action.payload

            newVendors.forEach((newVendor: CartVendor) => {
                const existingVendorIndex = state.vendors.findIndex(
                    (vendor: CartVendor) => String(vendor.id) === String(newVendor.id)
                )
                if (existingVendorIndex !== -1) {
                    const existingVendor = state.vendors[existingVendorIndex]
                    const updatedItems = [...existingVendor.items]
                    newVendor.items.forEach((newItem) => {
                        const existingItemIndex = updatedItems.findIndex(
                            (item) => item.product.id === newItem.product.id && item.price === newItem.price
                        )
                        if (existingItemIndex === -1 && productId.toString() === newItem.product.id.toString()) {
                            updatedItems.push(newItem)
                        } else if (overwrite) {
                            updatedItems[existingItemIndex] = newItem
                        }
                    })
                    existingVendor.items = updatedItems
                } else {
                    state.vendors.push(newVendor)
                }
            })
        }
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(cartApi.endpoints.getLastOpenedCart.matchFulfilled, (state, action) => {
                const customerLocationId = useGetCustomerLocation()
                const { originalArgs } = action.meta.arg
                if (customerLocationId === originalArgs) {
                    if (action.payload) {
                        state.vendors = action.payload.cartsVendors
                        state.updatedResponse = true
                    } else {
                        state.vendors = []
                    }
                    state.updatedResponse = true
                }
            })
            .addMatcher(cartApi.endpoints.updateCart.matchPending, (state, action) => {
                state.updateCart = false
            })
    }
})
export const {
    removeCartItem,
    updateCartItemQuantityUnit,
    updateCartItemQuantity,
    setVendors,
    resetCartItem,
    updateCartItemMargin,
    removeAllCartItems,
    addItemBeingAdded,
    removeItemBeingAdded,
    updateVendors
} = cartItemsSlice.actions

export default cartItemsSlice.reducer
