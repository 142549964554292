import {
    productAdded as fsProductAdded,
    cartViewed as fsCartViewed,
    checkoutStarted as fsCheckoutStarted,
    productsSearched as fsProductsSearched,
    orderCompleted as fsOrderCompleted,
    productRemoved as fsProductRemoved,
    batchProductAdded as fsBatchProductAdded,
    productClicked as fsProductClicked,
    productListViewed as fsProductListViewed,
    productListFiltered as fsProductListFiltered,
    marginEdit as fsMarginEdit,
    invoiceDownload as fsInvoiceDownload,
    inviteNewUser as fsInviteNewUser,
    payInvoice as fsPayInvoice,
    payInvoiceCompleted as fsPayInvoiceCompleted,
    orderGuideUploadInitiated as fsOrderGuideUploadInitiated,
    orderGuideUploadStarted as fsOrderGuideUploadStarted,
    identify as fsIdentify,
    inviteUserStep2 as fsInviteUser,
    inviteUserCompleted as fsInviteUserCompleted,
    orderGuideLock as fsOrderGuideLock,
    orderGuideShared as fsOrderGuideShared,
    broadcastMessage as fsBroadcastMessage
} from 'src/lib/events/fullStory/index'
import {
    beginCheckout as ga4BeginCheckout,
    addToCart as ga4AddToCart,
    removeFromCart as ga4RemoveFromCart,
    browseSearch as ga4BrowseSearch,
    viewCart as ga4ViewCart,
    viewItem as ga4ViewItem,
    viewList as ga4ViewList,
    orderCompleted as ga4OrderCompleted,
    userProperties as gaUserProperties
} from 'src/lib/events/googleAnalytics/index'
import { ProductAdded } from '../../lib/events/interfaces/ProductAdded'
import { CartViewed } from 'src/lib/events/interfaces/CartViewed'
import { CheckoutStarted } from 'src/lib/events/interfaces/CheckoutStarted'
import { ProductsSearched } from 'src/lib/events/interfaces/ProductsSearched'
import { OrderCompleted } from 'src/lib/events/interfaces/OrderCompleted'
import { ProductRemoved } from 'src/lib/events/interfaces/ProductRemoved'
import { BatchProductAdded } from '../../lib/events/interfaces/BatchProductAdded'
import { ProductClicked } from '../../lib/events/interfaces/ProductClicked'
import { ProductListViewed } from 'src/lib/events/interfaces/ProductListViewed'
import { ProductListFiltered } from 'src/lib/events/interfaces/ProductListFiltered'
import { PayInvoice } from 'src/lib/events/interfaces/PayInvoice'
import { User, Vendor } from 'gwy-sdk'
import { identifyVisitor } from '../../lib/events/hubspot/identifyVisitor'
import React from 'react'
import { setUser } from '../../lib/events/newRelic'
import { MarginEdit } from 'src/lib/events/interfaces/MarginEdit'
import { InvoiceDownload } from 'src/lib/events/interfaces/InvoiceDownload'
import { InviteNewUser } from 'src/lib/events/interfaces/InviteNewUser'
import { InviteUser } from 'src/lib/events/interfaces/InviteUser'
import { InviteUserCompleted } from 'src/lib/events/interfaces/InviteUserCompleted'
import { OrderGuideLock } from 'src/lib/events/interfaces/OrderGuideLock'
import { OrderGuideShared } from 'src/lib/events/interfaces/OrderGuideShared'
import { OrderGuideUploadInitiated } from 'src/lib/events/interfaces/OrderGuideUploadInitiated'
import { OrderGuideUploadStarted } from 'src/lib/events/interfaces/OrderGuideUploadStarted'
import { BroadcastMessage } from 'src/lib/events/interfaces/BroadcastMessage'

export const useMarketingEvents = () => {
    const productAdded = (payload: ProductAdded) => {
        fsProductAdded(payload)
        ga4AddToCart(payload)
    }

    const cartViewed = (payload: CartViewed) => {
        fsCartViewed(payload)
        ga4ViewCart(payload)
    }

    const marginEdit = (payload: MarginEdit) => {
        fsMarginEdit(payload)
    }

    const invoiceDownload = (payload: InvoiceDownload) => {
        fsInvoiceDownload(payload)
    }

    const batchProductAdded = (payload: BatchProductAdded) => {
        fsBatchProductAdded(payload)
    }
    const checkoutStarted = (payload: CheckoutStarted) => {
        fsCheckoutStarted(payload)
        ga4BeginCheckout(payload)
    }

    const productsSearched = React.useCallback((payload: ProductsSearched) => {
        fsProductsSearched(payload)
        ga4BrowseSearch(payload)
    }, [])

    const orderCompleted = React.useCallback((payload: OrderCompleted) => {
        fsOrderCompleted(payload)
        ga4OrderCompleted(payload)
    }, [])

    const productRemoved = (payload: ProductRemoved) => {
        fsProductRemoved(payload)
        ga4RemoveFromCart(payload)
    }

    const productClicked = (payload: ProductClicked) => {
        fsProductClicked(payload)
        ga4ViewItem(payload)
    }

    const productListViewed = React.useCallback((payload: ProductListViewed) => {
        fsProductListViewed(payload)
        ga4ViewList(payload)
    }, [])

    const productListFiltered = (payload: ProductListFiltered) => {
        fsProductListFiltered(payload)
    }

    const inviteNewUser = (payload: InviteNewUser) => {
        fsInviteNewUser(payload)
    }

    const inviteUser = (payload: InviteUser) => {
        fsInviteUser(payload)
    }

    const inviteUserCompleted = (payload: InviteUserCompleted) => {
        fsInviteUserCompleted(payload)
    }

    const payInvoice = (payload: PayInvoice) => {
        fsPayInvoice(payload)
    }

    const payInvoiceCompleted = (payload: PayInvoice) => {
        fsPayInvoiceCompleted(payload)
    }

    const orderGuideLock = (isLocked: boolean, payload: OrderGuideLock) => {
        fsOrderGuideLock(isLocked, payload)
    }

    const orderGuideShared = (payload: OrderGuideShared) => {
        fsOrderGuideShared(payload)
    }

    const orderGuideUploadStarted = (payload: OrderGuideUploadStarted) => {
        fsOrderGuideUploadStarted(payload)
    }

    const orderGuideUploadInitiated = (payload: OrderGuideUploadInitiated) => {
        fsOrderGuideUploadInitiated(payload)
    }

    const broadcastMessage = (payload: BroadcastMessage) => {
        fsBroadcastMessage(payload)
    }

    const identify = (payload: User, vendors: Vendor[], hubspotToken?: string) => {
        fsIdentify(payload, vendors)
        gaUserProperties(payload, vendors)
        setUser(payload)

        if (hubspotToken) {
            identifyVisitor(payload, hubspotToken)
        }
    }

    return {
        productAdded,
        cartViewed,
        batchProductAdded,
        checkoutStarted,
        productsSearched,
        orderCompleted,
        productRemoved,
        productClicked,
        productListViewed,
        productListFiltered,
        marginEdit,
        invoiceDownload,
        inviteNewUser,
        payInvoice,
        payInvoiceCompleted,
        identify,
        inviteUser,
        inviteUserCompleted,
        orderGuideLock,
        orderGuideShared,
        orderGuideUploadStarted,
        orderGuideUploadInitiated,
        broadcastMessage
    }
}
