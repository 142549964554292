import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { getTokenFromAuthCookie } from '../helpers/storage/users/getUser'
import defaultHeader from '../helpers/defaultHeader'

export const baseApi = createApi({
    reducerPath: 'api',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.NEXT_PUBLIC_GWY_API_ENDPOINT,
        prepareHeaders: (headers) => {
            const token = getTokenFromAuthCookie()
            const defaultHeaders = defaultHeader()

            headers.set('brand', defaultHeaders.brand)
            headers.set('deviceType', defaultHeaders.deviceType)
            headers.set('deviceId', defaultHeaders.deviceId)
            headers.set('pushId', defaultHeaders.pushId)
            headers.set('gmt', defaultHeaders.gmt)

            if (token) {
                headers.set('authorization', `Bearer ${token}`)
            }

            return headers
        }
    }),
    endpoints: () => ({})
})
