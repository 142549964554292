import marketingEventsConstants from 'src/constants/marketingEvents'
import * as FullStory from '@fullstory/browser'
import { ProductsSearched } from '../interfaces/ProductsSearched'

export const productsSearched = (payload: ProductsSearched) => {
    if (process.env.NEXT_PUBLIC_FULLSTORY_ORG_ID) {
        const { term, searchResults, itemListId, itemListName } = payload

        const data = {
            query_str: term,
            number_of_search_results_int: searchResults,
            item_list_id: itemListId,
            item_list_name: itemListName
        }

        FullStory.event(marketingEventsConstants.FULLSTORY_PRODUCTS_SEARCHED, data)
    }
}
