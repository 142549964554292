import { createSlice } from '@reduxjs/toolkit'
import { customersApi as customersApiNext } from '@api/customers'
import { customersApi } from '@api/customers'
import { useLocalStorage } from '@hooks/storage/useLocalStorage'
import { getCookieName } from '../helpers/cookies'
import { useCookie } from '@hooks/cookies/useCookie'
import { setCookie } from '../helpers/cookies'
import { Location } from 'gwy-sdk'

export interface CustomerLocationInterface {
    deliveryDate?: string
    cutOffTime?: string | null
}

const initialState: CustomerLocationInterface = {
    deliveryDate: undefined,
    cutOffTime: undefined
}

export const customerLocationsSlice = createSlice({
    name: 'customerLocations',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addMatcher(customersApi.endpoints.getLocationInfo.matchFulfilled, (state, action) => {
            const response = action.payload.response
            if (response) {
                state.deliveryDate = response.deliveryDate
                state.cutOffTime = response.cutOffTime
            }
        })
        builder.addMatcher(customersApiNext.endpoints.getCustomerById.matchFulfilled, (state, action) => {
            const { setItem, getItem } = useLocalStorage()
            const localStorageCustomerLocation = getItem('customerLocation')

            if (action.payload.locations?.[0]) {
                // Local Storage Section
                if (!localStorageCustomerLocation) {
                    setItem('customerLocation', JSON.stringify(action.payload.locations[0]))
                } else {
                    // Logic for case of locations that get deletted from the endpoint but are still in the localStorage
                    let locationObj: Location | null
                    try {
                        locationObj = JSON.parse(localStorageCustomerLocation)
                    } catch (error) {
                        locationObj = null
                    }
                    if (locationObj) {
                        const idLocation = locationObj.id
                        const cookieLocationExists = action.payload.locations.find((l) => l.id === idLocation)
                        if (!cookieLocationExists) {
                            setItem('customerLocation', JSON.stringify(action.payload.locations[0]))
                        }
                    }
                }

                // Cookie Section
                const cookieName = getCookieName('customer_location')
                const currentLocationCookie = useCookie(cookieName)
                const cookieLocationExists = action.payload.locations.find(
                    (l) => l.id.toString() === currentLocationCookie
                )
                if (!currentLocationCookie || cookieLocationExists === undefined) {
                    const locationId = action.payload.locations[0].id
                    setCookie({
                        name: cookieName,
                        value: locationId,
                        secure: true,
                        path: '/'
                    })
                }
            }
        })
    }
})

export default customerLocationsSlice.reducer
