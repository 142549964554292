import { AnyAction, combineReducers, configureStore } from '@reduxjs/toolkit'

import invoicesReducer from '@slices/invoices'
import authReducer from '@slices/auth'
import customerLocationsReducer from '@slices/customerLocations'
import cartsReducer from '@slices/carts'
import cartItemsReducer from '@slices/cartItems'
import upsellItemsReducer from '@slices/upsellItems'
import dialogsReducer from '@slices/dialogs'
import vendorReducer from '@slices/vendor'
import toastsReducer from '@slices/toasts'
import shoppingListsReducer from '@slices/shoppingLists'
import shoppingListItemsReducer from '@slices/shoppingListItems'
import snackbarsReducer from '@slices/snackbars'
import layoutsReducer from '@slices/layouts'
import checkoutReducer from '@slices/checkout'
import vendorProductsReducer from '@slices/vendorProducts'
import vendorProductInventoryReducer from '@slices/vendorProductInventory'
import vendorProductPricesReducer from '@slices/vendorProductPrices'
import marketingEventsReducer from '@slices/marketingEvents'
import pickupOrderReducer from '@slices/pickupOrder'

import { baseApi } from '@api/baseApi'
import { baseApi as baseApiNext } from '@api/next/baseApi'
import { authApi } from '@api/auth'

export const reducers = combineReducers({
    auth: authReducer,
    customerLocations: customerLocationsReducer,
    carts: cartsReducer,
    cartItems: cartItemsReducer,
    upsellItems: upsellItemsReducer,
    checkout: checkoutReducer,
    dialogs: dialogsReducer,
    vendor: vendorReducer,
    pickupOrder: pickupOrderReducer,
    invoices: invoicesReducer,
    shoppingListItems: shoppingListItemsReducer,
    shoppingLists: shoppingListsReducer,
    toasts: toastsReducer,
    vendorProducts: vendorProductsReducer,
    snackbars: snackbarsReducer,
    layouts: layoutsReducer,
    vendorProductInventory: vendorProductInventoryReducer,
    vendorProductPrices: vendorProductPricesReducer,
    marketingEvents: marketingEventsReducer,
    [baseApi.reducerPath]: baseApi.reducer,
    [baseApiNext.reducerPath]: baseApiNext.reducer
})

const rootReducer = (state: ReturnType<typeof reducers> | undefined, action: AnyAction) => {
    if (authApi.endpoints.login.matchPending(action)) {
        return reducers(undefined, action)
    }

    return reducers(state, action)
}

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(baseApi.middleware).concat(baseApiNext.middleware)
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
