import { getCookieName } from '../../helpers/cookies'

export const useDeleteAllCookies = () => {
    const deleteAllCookies = () => {
        /**
         * First delete auth cookie.
         * It needs to be specified explicitly because it's shared across domains
         **/
        const tokenCookieName = getCookieName('morsum_sso')
        document.cookie =
            `${tokenCookieName}=; domain=${process.env.NEXT_PUBLIC_APP_DOMAIN}; expires=` + new Date().toUTCString()

        /**
         * Then delete cookies for specific domain, excluding "consent-cookies"
         **/
        document.cookie.split(';').forEach((c) => {
            const cookieName = c.split('=')[0].trim()
            if (cookieName !== 'consent-cookies') {
                document.cookie = c
                    .replace(/^ +/, '')
                    .replace(/=.*/, `=;expires=` + new Date().toUTCString() + ';path=/')
            }
        })
    }

    return {
        deleteAllCookies
    }
}
