import { baseApi } from './baseApi'
import { Cart, CartTotal, UpdateCartRequest, DidYouForgetItemProduct, OrderCart } from 'gwy-sdk'
import cartsConstants from '../constants/carts'

const cartWithTag = baseApi.enhanceEndpoints({ addTagTypes: ['Cart', 'GetCartStats', 'GetUpsellItems'] })

export interface UpdateCartInterface {
    id: string
    body: UpdateCartRequest
}

export const cartApi = cartWithTag.injectEndpoints({
    endpoints: (builder) => ({
        getLastOpenedCart: builder.query<Cart, string>({
            query: (customerLocationId: string) => {
                return {
                    url: `customers/me/carts`,
                    params: {
                        customerLocationId,
                        status: cartsConstants.CART_OPEN_KEY
                    }
                }
            },
            transformResponse: (response: { response: [Cart] }) => response.response[0],
            providesTags: ['Cart']
        }),
        getCart: builder.query<Cart, string>({
            query: (cartId: string) => ({
                url: `carts/${cartId}`
            }),
            transformResponse: (response: { response: Cart }) => response.response
        }),
        updateCart: builder.mutation<Cart, UpdateCartInterface>({
            query: (request: UpdateCartInterface) => ({
                url: `carts/${request.id}`,
                method: 'put',
                body: request.body
            }),
            transformResponse: (response: { response: Cart }) => response.response,
            invalidatesTags: ['GetCartStats', 'GetUpsellItems']
        }),
        getCartStats: builder.query<CartTotal, string>({
            query: (cartId: string) => ({
                url: `carts/${cartId}/totals`
            }),
            transformResponse: (response: { response: CartTotal }) => response.response,
            providesTags: ['GetCartStats']
        }),
        refetchLastCart: builder.mutation<null, void>({
            queryFn: () => ({ data: null }),
            invalidatesTags: ['Cart']
        }),
        getDidYouForgetItems: builder.query({
            query: (cartId: string) => ({
                url: `carts/${cartId}/did-you-forget`
            }),
            transformResponse: (response: { response: DidYouForgetItemProduct[] }) => response.response,
            providesTags: ['GetUpsellItems']
        }),
        orderAgain: builder.query<OrderCart, string>({
            query: (orderId: string) => ({
                url: `orders/${orderId}/carts`,
                method: 'post'
            }),
            transformResponse: (response: { response: OrderCart }) => response.response
        })
    })
})

export const {
    useGetLastOpenedCartQuery,
    useUpdateCartMutation,
    useLazyGetCartQuery,
    useLazyGetCartStatsQuery,
    useRefetchLastCartMutation,
    useGetDidYouForgetItemsQuery,
    useLazyOrderAgainQuery
} = cartApi
