import marketingEventsConstants from 'src/constants/marketingEvents'
import * as FullStory from '@fullstory/browser'
import { CheckoutStarted } from '../interfaces/CheckoutStarted'

export const checkoutStarted = (payload: CheckoutStarted) => {
    if (process.env.NEXT_PUBLIC_FULLSTORY_ORG_ID) {
        const { cartId, checkout, customerLocation } = payload

        const orderIds: string[] = []
        checkout?.checkoutsVendors.forEach((checkoutVendor) => {
            checkoutVendor.orders.forEach((order) => {
                orderIds.push(String(order.id))
            })
        })

        const listOfVendorsNames: string[] = []
        checkout?.checkoutsVendors.forEach((checkoutVendor) => {
            listOfVendorsNames.push(checkoutVendor.vendor.name)
        })

        const data = {
            order_id_strs: orderIds,
            cart_id_str: cartId,
            vendor_name_strs: listOfVendorsNames,
            operator_location_str: customerLocation?.name,
            currency_str: cartId && marketingEventsConstants.CURRENCY_USD,
            value_real: checkout?.totals.total,
            revenue_real: checkout?.totals.total
        }

        FullStory.event(marketingEventsConstants.FULLSTORY_CHECKOUT_STARTED, data)
    }
}
