export const useLocalStorage = () => {
    /**
     * Wrapper to set local storage with NextJS
     * @param name
     * @param value
     */
    const setItem = (name: string, value: string) => {
        if (typeof window === 'undefined') return null
        localStorage.setItem(name, value)
    }

    /**
     * Wrapper to get local storage with NextJS
     * @param name
     */
    const getItem = (name: string) => {
        if (typeof window === 'undefined') return null
        return localStorage.getItem(name)
    }

    return {
        setItem,
        getItem
    }
}
