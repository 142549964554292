import invoicesConstants from '../../constants/invoices'
import { DownloadInvoicesRequest } from 'gwy-sdk'
import { downloadInvoicesService } from '../../services/invoices'
import fileDownload from 'js-file-download'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { handleThunkError } from '../../helpers/handleThunkError'

export const downloadInvoices = createAsyncThunk(
    invoicesConstants.DOWNLOAD_INVOICES,
    async (request: DownloadInvoicesRequest, { rejectWithValue }) => {
        try {
            const response = await downloadInvoicesService(request)
            fileDownload(response.data, 'invoices.pdf')
        } catch (error) {
            return handleThunkError(error, rejectWithValue)
        }
    }
)
