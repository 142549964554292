import * as FullStory from '@fullstory/browser'
import { BroadcastMessage } from '../interfaces/BroadcastMessage'

export const broadcastMessage = (payload: BroadcastMessage) => {
    if (process.env.NEXT_PUBLIC_FULLSTORY_ORG_ID) {
        const data = {
            vendor_name_str: payload.vendorName,
            operator_location_name_str: payload.operatorLocation,
            centralUser_str: payload.centralUserId
        }

        FullStory.event(payload.broadcastEventProperty, data)
    }
}
