import marketingEventsConstants from 'src/constants/marketingEvents'
import * as FullStory from '@fullstory/browser'
import { CartViewed } from '../interfaces/CartViewed'

export const cartViewed = (payload: CartViewed) => {
    if (process.env.NEXT_PUBLIC_FULLSTORY_ORG_ID) {
        const { cartId, total } = payload

        const data = {
            cart_id_str: cartId,
            currency_str: cartId && marketingEventsConstants.CURRENCY_USD,
            value_real: total,
            revenue_real: total
        }

        FullStory.event(marketingEventsConstants.FULLSTORY_CART_VIEWED, data)
    }
}
