import marketingEventsConstants from 'src/constants/marketingEvents'
import * as FullStory from '@fullstory/browser'
import { PayInvoice } from '../interfaces/PayInvoice'

export const payInvoice = (payload: PayInvoice) => {
    if (process.env.NEXT_PUBLIC_FULLSTORY_ORG_ID) {
        const { invoiceNumber, orderId, vendorName, itemListName, invoiceValue, invoiceTotal } = payload

        const data = {
            invoice_number_str: invoiceNumber,
            order_id_str: orderId,
            vendor_name_str: vendorName,
            item_list_name_str: itemListName,
            currency_str: marketingEventsConstants.CURRENCY_USD,
            invoice_value_real: invoiceValue,
            invoice_total_real: invoiceTotal
        }

        FullStory.event(marketingEventsConstants.FULLSTORY_PAY_INVOICE, data)
    }
}
