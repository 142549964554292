import { Configuration, DownloadInvoicesRequest, InvoiceApiFactory } from 'gwy-sdk'
import defaultHeader from '../helpers/defaultHeader'
import configuration from '../helpers/configuration'

export const downloadInvoicesService = async (body: DownloadInvoicesRequest) => {
    const headers = defaultHeader()
    const apiConfiguration: Configuration = configuration()

    const invoicesApi = InvoiceApiFactory(apiConfiguration)

    return await invoicesApi.downloadInvoices(
        headers.brand,
        headers.deviceId,
        headers.pushId,
        headers.gmt,
        body,
        headers.deviceType,
        {
            responseType: 'blob'
        }
    )
}
