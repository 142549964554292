import authConstants from '../../constants/auth'
import { logoutService } from '../../services/auth'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { handleThunkError } from '../../helpers/handleThunkError'

export const logout = createAsyncThunk(authConstants.LOGOUT, async (_, { rejectWithValue }) => {
    try {
        await logoutService()
        sessionStorage.clear()
        localStorage.clear()

        if (window.HubSpotConversations !== undefined) {
            window.hsConversationsSettings = undefined
            window.HubSpotConversations.clear({ resetWidget: true })
            window.HubSpotConversations.widget.remove()
        }
    } catch (error) {
        return handleThunkError(error, rejectWithValue)
    }
})
