import { useLazyGetCustomerByIdQuery } from '@api/customers'
import { useLazyGetVendorsByCustomerIdQuery } from '@api/next/vendors'
import { User } from 'gwy-sdk'
import { useCreateVisitorTokenMutation } from '@api/next/hubspot'
import { useMarketingEvents } from '@hooks/events/useMarketingEvents'

export const useIdentify = () => {
    const { identify: identifyInProviders } = useMarketingEvents()

    const [getCustomerById] = useLazyGetCustomerByIdQuery()
    const [getVendorsByCustomerId] = useLazyGetVendorsByCustomerIdQuery()
    const [createVisitorToken] = useCreateVisitorTokenMutation()

    const fetchCustomer = async () => {
        try {
            const customerResponse = await getCustomerById('me')

            if (customerResponse.isSuccess) {
                return customerResponse.data
            }
        } catch (e) {
            throw Error('Unable to get customer information')
        }
    }

    const fetchVendors = async () => {
        try {
            const customer = await fetchCustomer()

            const vendorsResponse = await getVendorsByCustomerId({
                customerId: 'me',
                customerLocationId: customer!.locations![0].id
            })

            if (vendorsResponse.isSuccess) {
                return vendorsResponse.data.response
            }
        } catch (e) {
            throw Error('Unable to get vendor information')
        }
    }

    const createHubspotVisitorToken = async (user: User) => {
        try {
            const createVisitorTokenResponse = await createVisitorToken({
                email: user.email,
                firstName: user.name,
                lastName: String(user.surname)
            }).unwrap()

            return createVisitorTokenResponse.token
        } catch (e) {
            // TODO: Notify New Relic
            console.info('Unable to get hubspot visitor token')
        }
    }

    const identify = async (user: User) => {
        const vendors = await fetchVendors()
        const hubspotToken = await createHubspotVisitorToken(user)

        if (!vendors) {
            throw Error('Unable to get vendor information')
        }

        identifyInProviders(user, vendors, hubspotToken)
    }

    return {
        identify
    }
}
