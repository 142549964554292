export function useCookie(cookieName: string) {
    const searchName = cookieName + '='
    const cookiesArray = document.cookie.split(';')

    for (let i = 0; i < cookiesArray.length; i++) {
        let cookieElement = cookiesArray[i]
        while (cookieElement.charAt(0) === ' ') {
            cookieElement = cookieElement.substring(1)
        }

        if (cookieElement.indexOf(searchName) === 0) {
            return cookieElement.substring(searchName.length, cookieElement.length)
        }
    }

    return ''
}
