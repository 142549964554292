import { Configuration, ConfigurationParameters } from 'gwy-sdk'
import { getUser } from './storage/users/getUser'

const configuration = (params?: ConfigurationParameters): Configuration => {
    const user = getUser()

    const configurationParameters: ConfigurationParameters = {
        ...params,
        basePath: `${process.env.NEXT_PUBLIC_GWY_API_ENDPOINT}`
    }

    if (user != null && user.accessToken) {
        configurationParameters.accessToken = user.accessToken.token
    }

    return new Configuration(configurationParameters)
}

export default configuration
