import marketingEventsConstants from 'src/constants/marketingEvents'
import { OrderCompleted } from '../interfaces/OrderCompleted'

export const orderCompleted = (payload: OrderCompleted) => {
    if (process.env.NEXT_PUBLIC_GA4_KEY) {
        const { orders, cartId, customerLocation } = payload

        orders?.forEach((order) => {
            const data = {
                order_id: order.id,
                cart_id: String(cartId),
                operator_location: customerLocation?.name,
                currency: marketingEventsConstants.CURRENCY_USD,
                value: order.subtotal.total,
                revenue: order.subtotal.total,
                items: order.items.map((item) => ({
                    item_id: item.product.id,
                    external_id: item.product.externalId,
                    vendor_name: item.product.vendor.name,
                    item_name: item.product.name,
                    price: item.price,
                    brand: item.product.brand?.name,
                    quantity: item.quantity
                }))
            }
            window.dataLayer.push({ ecommerce: null })
            window.dataLayer.push({
                event: marketingEventsConstants.GOOGLE_ANALYTICS_ORDER_COMPLETED,
                ecommerce: data
            })
        })
    }
}
