import { SerializedError } from '@reduxjs/toolkit'

export function serializeAPIError(err: any) {
    if (err.response?.data) {
        const responseData = err.response?.data
        return {
            message: responseData?.error.message,
            code: responseData?.error.code,
            stack: responseData?.exception
        } as SerializedError
    }

    return null
}
