import React, { PropsWithChildren } from 'react'
import { ThemeProvider } from '@mui/material/styles'
import theme from 'morsum-web-ui-kit/dist/theme'
import { SWTheme } from 'morsum-web-ui-kit'

const SWDomains = ['https://s-wfoods.ancho.vi', 'https://sandwstg.morsum.co', 'https://sandwdev.morsum.co']

const WhiteLabelThemeProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const defaultTheme = React.useMemo(() => {
        if (typeof window === 'undefined') {
            return theme
        }

        if (SWDomains.includes(window.location.origin)) {
            return SWTheme
        } else {
            return theme
        }
    }, [])

    return <ThemeProvider theme={defaultTheme}>{children}</ThemeProvider>
}

export default WhiteLabelThemeProvider
