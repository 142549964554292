import { baseApi } from './baseApi'
import { CheckoutResponse, ConfirmCheckoutRequest, UpdateCartRequestProducts } from 'gwy-sdk'

interface CheckoutByCartIdInterface {
    cartId: string
}

interface PostCheckoutByCartIdInterface {
    cartId: string
    products: UpdateCartRequestProducts[]
}

interface ConfirmCheckoutByCartIdInterface {
    cartId: string
    payload: ConfirmCheckoutRequest
}

const checkoutWithTag = baseApi.enhanceEndpoints({
    addTagTypes: ['getCheckoutByCartId']
})

export const checkoutApi = checkoutWithTag.injectEndpoints({
    endpoints: (builder) => ({
        getCheckoutByCartId: builder.query<CheckoutResponse, CheckoutByCartIdInterface>({
            query: (request: CheckoutByCartIdInterface) => {
                const { cartId } = request

                return {
                    url: `carts/${cartId}/checkout`
                }
            },
            providesTags: ['getCheckoutByCartId']
        }),
        checkoutByCartId: builder.mutation({
            query: (request: PostCheckoutByCartIdInterface) => {
                const { cartId, ...rest } = request

                return {
                    url: `carts/${cartId}/checkout`,
                    method: 'post',
                    body: rest
                }
            },
            invalidatesTags: ['getCheckoutByCartId']
        }),
        confirmCheckoutByCartId: builder.mutation({
            query: (request: ConfirmCheckoutByCartIdInterface) => {
                const { cartId, payload } = request

                return {
                    url: `carts/${cartId}/checkout/confirm`,
                    method: 'post',
                    body: payload
                }
            },
            invalidatesTags: ['getCheckoutByCartId']
        })
    })
})

export const { useGetCheckoutByCartIdQuery, useCheckoutByCartIdMutation, useConfirmCheckoutByCartIdMutation } =
    checkoutApi
