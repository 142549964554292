import { createSlice, SerializedError } from '@reduxjs/toolkit'
import { logout } from '@actions/auth'
import { User } from 'gwy-sdk'
import { authApi } from '@api/auth'

interface AuthSliceInitialStateInterface {
    requests: Record<string, boolean>
    error?: SerializedError
    user?: User
}

const initialState: AuthSliceInitialStateInterface = {
    requests: {
        login: false,
        logout: false,
        resetPasswordWithToken: false
    }
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addMatcher(authApi.endpoints.login.matchFulfilled, (state, action) => {
            state.user = action.payload.response
        })

        builder.addMatcher(
            (action) => [logout.pending.type].includes(action.type),
            (state, action) => {
                const requestType = action.type.split('/')[0]
                state.requests[requestType] = true
            }
        )

        builder.addMatcher(
            (action) => [logout.fulfilled.type].includes(action.type),
            (state, action) => {
                const requestType = action.type.split('/')[0]
                state.requests[requestType] = false
            }
        )

        builder.addMatcher(
            (action) => [logout.rejected.type].includes(action.type),
            (state, action) => {
                state.error = action.payload
                const requestType = action.type.split('/')[0]
                state.requests[requestType] = false
            }
        )

        builder
            .addMatcher(authApi.endpoints.login.matchFulfilled, (state, action) => {
                state.requests[action.meta.arg.endpointName] = false
            })
            .addMatcher(authApi.endpoints.login.matchPending, (state, action) => {
                state.requests[action.meta.arg.endpointName] = true
            })
            .addMatcher(authApi.endpoints.login.matchFulfilled, (state, action) => {
                state.requests[action.meta.arg.endpointName] = false
            })
    }
})

export default authSlice.reducer
