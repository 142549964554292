import { BaseRequestInterface } from 'src/interfaces/common/BaseRequestInterface'
import { baseApi } from './baseApi'
import { VendorsResponse } from 'gwy-sdk'

interface GetVendorsByCustomerIdInterface extends BaseRequestInterface {
    customerId: string
    customerLocationId: string
}

export const vendorsApi = baseApi.injectEndpoints({
    overrideExisting: true,
    endpoints: (builder) => ({
        getVendorsByCustomerId: builder.query<VendorsResponse, GetVendorsByCustomerIdInterface>({
            query: (request: GetVendorsByCustomerIdInterface) => {
                const { customerId, ...rest } = request

                return {
                    url: `/api/customers/${customerId}/vendors`,
                    params: rest
                }
            }
        })
    })
})

export const { useGetVendorsByCustomerIdQuery, useLazyGetVendorsByCustomerIdQuery } = vendorsApi
