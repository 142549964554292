import { createSlice } from '@reduxjs/toolkit'

interface PickupAddressInfo {
    name: ''
    address: ''
}

interface PickupOrderInterface {
    pickupAddress?: PickupAddressInfo
    isPickupOrder: boolean
}

const initialState: PickupOrderInterface = {
    pickupAddress: undefined,
    isPickupOrder: false
}

export const pickupOrder = createSlice({
    name: 'pickupOrder',
    initialState,
    reducers: {
        setPickupAddress: (state, action) => {
            state.pickupAddress = {
                ...state.pickupAddress,
                name: action.payload.name,
                address: action.payload.address
            }
        },
        setIsPickupOrder: (state, action) => {
            state.isPickupOrder = action.payload
        }
    }
})

export const { setPickupAddress, setIsPickupOrder } = pickupOrder.actions

export default pickupOrder.reducer
