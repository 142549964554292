import environmentsConstants from '../../constants/enviroments'

export const getCookieName = (cookie: string) => {
    let envCookie

    if (process.env.NEXT_PUBLIC_APP_ENV === environmentsConstants.PRODUCTION) {
        envCookie = cookie
    } else {
        envCookie = `${cookie}-${process.env.NEXT_PUBLIC_APP_ENV}`
    }

    return envCookie
}
