import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface LayoutsInitialStateInterface {
    position: {
        x: number
        y: number
    }
    showAddVendorProductToShoppingListMenu: boolean
    showCartDsrView: boolean
    showVendorDetailsSearchView: boolean
    showVendorDetailsHiddenOptions: boolean
    showShoppingListMenu: boolean
    showResetPasswordConfirmation: boolean
    showCloneBanner: boolean
    uploadProgress: number
}

const initialState: LayoutsInitialStateInterface = {
    position: {
        x: 0,
        y: 0
    },
    showCartDsrView: false,
    showVendorDetailsSearchView: false,
    showVendorDetailsHiddenOptions: false,
    showAddVendorProductToShoppingListMenu: false,
    showShoppingListMenu: false,
    showResetPasswordConfirmation: false,
    showCloneBanner: true,
    uploadProgress: 0
}

export const layoutsSlice = createSlice({
    name: 'layouts',
    initialState,
    reducers: {
        setShowCloneBanner: (state, action) => {
            state.showCloneBanner = action.payload
        },
        setPosition: (state, action: PayloadAction<{ x: number; y: number }>) => {
            state.position = action.payload
        },
        showCartDsrViewLayout: (state) => {
            state.showCartDsrView = true
        },
        hideCartDsrViewLayout: (state) => {
            state.showCartDsrView = false
        },
        showVendorDetailsSearchViewLayout: (state) => {
            state.showVendorDetailsSearchView = true
        },
        hideVendorDetailsSearchViewLayout: (state) => {
            state.showVendorDetailsSearchView = false
        },
        showVendorDetailsHiddenOptionsLayout: (state) => {
            state.showVendorDetailsHiddenOptions = true
        },
        hideVendorDetailsHiddenOptionsLayout: (state) => {
            state.showVendorDetailsHiddenOptions = false
        },
        showAddVendorProductToShoppingListMenu: (state) => {
            state.showAddVendorProductToShoppingListMenu = true
        },
        hideAddVendorProductToShoppingListMenu: (state) => {
            state.showAddVendorProductToShoppingListMenu = false
        },
        showShoppingListMenuLayout: (state) => {
            state.showShoppingListMenu = true
        },
        hideShoppingListMenuLayout: (state) => {
            state.showShoppingListMenu = false
        },
        showResetPasswordConfirmation: (state) => {
            state.showResetPasswordConfirmation = true
        },
        hideResetPasswordConfirmation: (state) => {
            state.showResetPasswordConfirmation = false
        },
        setUploadProgress: (state, action: PayloadAction<number>) => {
            state.uploadProgress = action.payload
        }
    }
})

export const {
    setPosition,
    showCartDsrViewLayout,
    hideCartDsrViewLayout,
    hideVendorDetailsSearchViewLayout,
    showVendorDetailsSearchViewLayout,
    hideVendorDetailsHiddenOptionsLayout,
    showVendorDetailsHiddenOptionsLayout,
    hideAddVendorProductToShoppingListMenu,
    showAddVendorProductToShoppingListMenu,
    showShoppingListMenuLayout,
    hideShoppingListMenuLayout,
    hideResetPasswordConfirmation,
    showResetPasswordConfirmation,
    setShowCloneBanner,
    setUploadProgress
} = layoutsSlice.actions

export default layoutsSlice.reducer
