import React from 'react'
import { Box, Button, Container, Typography } from '@mui/material'
import Cat from '@assets/cat.png'
import Image from 'next/legacy/image'

interface FallbackErrorInterface {
    error: Error
    compact?: boolean
}

const FallbackError: React.FC<FallbackErrorInterface> = ({ error, compact }) => {
    const message = error.message || 'An error has occurred, please try again later.'

    return (
        <Container>
            <Box
                sx={{
                    minHeight: compact ? '0px' : '100vh',
                    margin: compact ? '60px 0px' : '0px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <Image
                    src={Cat}
                    alt={'cat'}
                />
                <Box sx={{ textAlign: 'center', marginTop: 3, marginBottom: 3 }}>
                    <Typography
                        variant={'h5'}
                        color={'secondary'}
                        gutterBottom={true}
                    >
                        Oops...
                    </Typography>
                    <Typography variant={'body2'}>{message}</Typography>
                </Box>

                <Button
                    onClick={() => {
                        window.location.reload()
                    }}
                    variant={'outlined'}
                >
                    Refresh site
                </Button>
            </Box>
        </Container>
    )
}

export default FallbackError
