enum marketingEventsConstants {
    FULLSTORY_PRODUCT_ADDED = 'Product Added',
    FULLSTORY_CART_VIEWED = 'Cart Viewed',
    FULLSTORY_CHECKOUT_STARTED = 'Checkout Started',
    FULLSTORY_PRODUCTS_SEARCHED = 'Products Searched',
    FULLSTORY_ORDER_COMPLETED = 'Order Completed',
    FULLSTORY_PRODUCT_REMOVED = 'Product Removed',
    FULLSTORY_PRODUCT_LIST_VIEWED = 'Product List Viewed',
    FULLSTORY_MARGIN_EDIT = 'Margin Edit',
    FULLSTORY_INVOICE_DOWNLOAD = 'Invoice Download',
    FULLSTORY_PRODUCT_LIST_FILTERED = 'Product List Filtered',
    FULLSTORY_INVITE_USER = 'Invite user',
    FULLSTORY_INVITE_USER_STEP_2 = 'Invite user step 2',
    FULLSTORY_INVITE_USER_COMPLETED = 'Invite user completed',
    FULLSTORY_PAY_INVOICE = 'Pay Invoice',
    FULLSTORY_PAY_INVOICE_COMPLETED = 'Pay Invoice Completed',
    CURRENCY_USD = 'USD',
    ANALYTICS_PRODUCT_CATALOG = 'Product Catalog',
    ANALYTICS_PRODUCT_DETAILS_PAGE = 'Product Details Page',
    FULLSTORY_BATCH_PRODUCT_ADDED = 'Batch Product Added',
    ANALYTICS_DASHBOARD = 'Dashboard',
    ANALYTICS_CART = 'Cart',
    ANALYTICS_DID_YOU_FORGET = 'Did You Forget',
    ANALYTICS_CART_DRAWER = 'Pop Up Cart',
    ANALYTICS_ORDERS = 'Orders',
    ANALYTICS_ORDER_GUIDE = 'Order Guide',
    ANALYTICS_ORDER_HISTORY = 'Order History',
    ANALYTICS_ORDER_DETAIL = 'Order Detail',
    ANALYTICS_INVOICES = 'Invoices',
    ANALYTICS_INVITE_NEW_USER = 'Invite new user',
    ANALYTICS_INVITE_USER = 'Invite user',
    ANALYTICS_INVITE_USER_COMPLETED = 'Invite user completed',
    FULLSTORY_PRODUCT_CLICKED = 'Product Clicked',
    GOOGLE_ANALYTICS_BEGIN_CHECKOUT = 'begin_checkout',
    FULLSTORY_LAST_PURCHASED = 'Last Purchased',
    FULLSTORY_BRAND = 'Brand',
    GOOGLE_ANALYTICS_ADD_TO_CART = 'add_to_cart',
    GOOGLE_ANALYTICS_ORDER_COMPLETED = 'purchase',
    GOOGLE_ANALYTICS_REMOVE_FROM_CART = 'remove_from_cart',
    GOOGLE_ANALYTICS_SEARCH = 'search',
    GOOGLE_ANALYTICS_VIEW_CART = 'view_cart',
    GOOGLE_ANALYTICS_VIEW_ITEM = 'view_item',
    GOOGLE_ANALYTICS_LIST_VIEWED = 'view_item_list',
    FULLSTORY_ORDER_GUIDE_LOCKED = 'Order Guide Locked',
    FULLSTORY_ORDER_GUIDE_UNLOCKED = 'Order Guide Unlocked',
    FULLSTORY_ORDER_GUIDE_SHARED = 'Order Guide Shared',
    FULLSTORY_ORDER_GUIDE_UPLOAD_STARTED = 'Upload Started',
    FULLSTORY_ORDER_GUIDE_UPLOAD_INITIATED = 'Upload Initiated',
    FULLSTORY_BROADCAST_MESSAGE_CTA_STARTED = 'Broadcast message CTA started',
    FULLSTORY_BROADCAST_MESSAGE_SENT = 'Broadcast message sent'
}

export default marketingEventsConstants
