import marketingEventsConstants from 'src/constants/marketingEvents'
import * as FullStory from '@fullstory/browser'
import { OrderGuideLock } from '../interfaces/OrderGuideLock'

export const orderGuideLock = (isLocked: boolean, payload: OrderGuideLock) => {
    if (process.env.NEXT_PUBLIC_FULLSTORY_ORG_ID) {
        const { itemListName, itemListId, vendorName, operatorLocationId, operatorLocationName } = payload

        const data = {
            item_list_id_str: String(itemListId),
            item_list_name_str: itemListName,
            vendor_name_str: vendorName,
            operator_location_id_str: String(operatorLocationId),
            operator_location_name_str: operatorLocationName
        }
        const action = isLocked
            ? marketingEventsConstants.FULLSTORY_ORDER_GUIDE_LOCKED
            : marketingEventsConstants.FULLSTORY_ORDER_GUIDE_UNLOCKED
        FullStory.event(action, data)
    }
}
