import marketingEventsConstants from 'src/constants/marketingEvents'
import { ProductClicked } from '../interfaces/ProductClicked'

export const viewItem = (payload: ProductClicked) => {
    if (process.env.NEXT_PUBLIC_GA4_KEY) {
        const data = {
            currency: marketingEventsConstants.CURRENCY_USD,
            items: [
                {
                    item_id: payload.itemId,
                    item_list_name: payload.itemListName
                }
            ]
        }

        window.dataLayer.push({ ecommerce: null })
        window.dataLayer.push({
            event: marketingEventsConstants.GOOGLE_ANALYTICS_VIEW_ITEM,
            ecommerce: data
        })
    }
}
