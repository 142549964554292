import { baseApi } from './baseApi'
import { VendorProductsSearchRequest } from '../interfaces/VendorProductsSearchRequest'
import { VendorProductsSearchResponse } from 'gwy-sdk'

const vendorProductsWithTag = baseApi.enhanceEndpoints({
    addTagTypes: ['searchVendorProducts', 'getProductPrices']
})

export const vendorProductsApi = vendorProductsWithTag.injectEndpoints({
    overrideExisting: true,
    endpoints: (builder) => ({
        searchVendorProducts: builder.query<VendorProductsSearchResponse, VendorProductsSearchRequest>({
            query: (request: VendorProductsSearchRequest) => {
                const { vendorId, page, perPage, ...rest } = request

                return {
                    url: `vendors/${vendorId}/products/search`,
                    method: 'post',
                    params: {
                        page,
                        perPage
                    },
                    body: {
                        ...rest
                    }
                }
            },
            providesTags: ['searchVendorProducts']
        }),
        getProductsPriceByIds: builder.query({
            query: (request) => {
                const { vendorId, customerLocationId, productsIds } = request
                const productIdsQuery = productsIds.map((id: string) => `productIds[]=${id}`).join('&')

                return {
                    url: `vendors/${vendorId}/products/prices?${productIdsQuery}`,
                    params: {
                        customerLocationId
                    }
                }
            }
        })
    })
})

export const { useLazySearchVendorProductsQuery, useGetProductsPriceByIdsQuery, useLazyGetProductsPriceByIdsQuery } =
    vendorProductsApi
