import { createSlice } from '@reduxjs/toolkit'

export interface SnackbarsInitialStateInterface {
    showGenericErrorSnackbar: boolean
    showGenericInfoSnackbar: boolean
    showRequestCSVSnackbar: boolean
    showGenericProgressSnackbar: boolean
    showItemAddedToShoppingListSnackbar: boolean
    showBroadcastMessageSnackbar: boolean
    showInvitationEmailSnackbar: boolean
    infoMessage?: string
    errorMessage?: string
    requestCSVmessage?: string
    progressMessage?: string
    progress?: number
    meta?: any
}

const initialState: SnackbarsInitialStateInterface = {
    showGenericErrorSnackbar: false,
    showGenericInfoSnackbar: false,
    showRequestCSVSnackbar: false,
    showGenericProgressSnackbar: false,
    showItemAddedToShoppingListSnackbar: false,
    showBroadcastMessageSnackbar: false,
    showInvitationEmailSnackbar: false
}

export const snackbarsSlice = createSlice({
    name: 'snackbars',
    initialState,
    reducers: {
        showGenericErrorSnackbar: (state, action) => {
            state.showGenericErrorSnackbar = true
            state.errorMessage = action.payload
        },
        showGenericInfoSnackbar: (state, action) => {
            state.showGenericInfoSnackbar = true
            state.infoMessage = action.payload
        },
        showRequestCSVSnackbar: (state, action) => {
            state.showRequestCSVSnackbar = true
            state.requestCSVmessage = action.payload
        },
        showGenericProgressSnackbar: (state, action) => {
            state.showGenericProgressSnackbar = true
            state.progressMessage = action.payload.message
            state.progress = action.payload.progress
        },
        showItemAddedToShoppingListSnackbar: (state, action) => {
            state.showItemAddedToShoppingListSnackbar = true
            state.meta = action.payload
        },
        showBroadcastMessageSnackbar: (state, action) => {
            state.showBroadcastMessageSnackbar = true
            state.infoMessage = action.payload
        },
        showInvitationEmailSnackbar: (state, action) => {
            state.showInvitationEmailSnackbar = true
            state.meta = action.payload
        },
        hideGenericErrorSnackbar: (state) => {
            state.showGenericErrorSnackbar = false
        },
        hideGenericInfoSnackbar: (state) => {
            state.showGenericInfoSnackbar = false
        },
        hideGenericProgressSnackbar: (state) => {
            state.showGenericProgressSnackbar = false
        },
        hideItemAddedToShoppingListSnackbar: (state) => {
            state.showItemAddedToShoppingListSnackbar = false
            state.meta = undefined
        },
        hideInvitationEmailSnackbar: (state) => {
            state.showInvitationEmailSnackbar = false
            state.meta = undefined
        },
        hideBroadcastMessageSnackbar: (state) => {
            state.showBroadcastMessageSnackbar = false
            state.infoMessage = undefined
        },
        hideRequestCSVSnackbar: (state) => {
            state.showRequestCSVSnackbar = false
        }
    }
})

export const {
    hideRequestCSVSnackbar,
    showRequestCSVSnackbar,
    hideGenericErrorSnackbar,
    showGenericErrorSnackbar,
    hideGenericInfoSnackbar,
    showGenericInfoSnackbar,
    hideGenericProgressSnackbar,
    showGenericProgressSnackbar,
    showItemAddedToShoppingListSnackbar,
    hideItemAddedToShoppingListSnackbar,
    showBroadcastMessageSnackbar,
    hideBroadcastMessageSnackbar,
    showInvitationEmailSnackbar,
    hideInvitationEmailSnackbar
} = snackbarsSlice.actions

export default snackbarsSlice.reducer
