import marketingEventsConstants from 'src/constants/marketingEvents'
import { ProductAdded } from '../interfaces/ProductAdded'
import * as FullStory from '@fullstory/browser'

export const productAdded = (payload: ProductAdded) => {
    if (process.env.NEXT_PUBLIC_FULLSTORY_ORG_ID) {
        const { cartId, vendorProduct, orderedPrices, pagination, vendorProducts, vendorId } = payload

        const indexForPosition =
            vendorProducts && vendorProducts.map((product: { id: string }) => product.id).indexOf(vendorId)
        const position =
            pagination && indexForPosition
                ? Number(pagination?.currentPage * pagination?.perPage - pagination?.perPage + indexForPosition)
                : vendorProducts?.map((position) => position.id).indexOf(vendorProduct!.id)

        const getPrices =
            orderedPrices && orderedPrices.find((price) => price.packaging.unit.id == String(payload.priceId))

        const valueReal = getPrices && getPrices?.price * payload.quantity

        const data = {
            cart_id_str: String(cartId),
            item_id_str: String(vendorId),
            centralization_id_str: null,
            external_id_str: vendorProduct?.externalId,
            vendor_name_str: vendorProduct?.vendor?.name,
            item_list_id_str: payload.itemListId,
            item_list_name_str: payload.itemListName,
            brand_str: vendorProduct?.brand?.name,
            name_str: vendorProduct?.name,
            currency_str: marketingEventsConstants.CURRENCY_USD,
            value_real: Number(valueReal?.toFixed(2)),
            price_real: getPrices?.price,
            item_category_5_str: String(getPrices?.unit.name),
            quantity_int: payload.quantity,
            position_int: position,
            url_str: payload.url
        }

        FullStory.event(marketingEventsConstants.FULLSTORY_PRODUCT_ADDED, data)
    }
}
