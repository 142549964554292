import { memo } from 'react'
import Script from 'next/script'

declare global {
    interface Window {
        gtag: (...args: any[]) => void
        dataLayer: Record<string, any>
    }
}

const GTM_KEY = process.env.NEXT_PUBLIC_GTM_KEY

const GoogleTagManager = () => {
    return (
        <Script
            id={'google-tag-manager'}
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
                __html: `
                    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                    })(window,document,'script','dataLayer','${GTM_KEY}')
                `
            }}
        />
    )
}

export default memo(GoogleTagManager)
