import { baseApi } from './baseApi'
import { LoginRequest, LoginResponse, UpdatePasswordRequest, User, SendResetPasswordEmailRequest } from 'gwy-sdk'

interface VerifyEmailInterface {
    userId: string
    expires: string
    hash: string
    signature: string
}

interface SendResetPasswordEmailRequestInterface extends SendResetPasswordEmailRequest {
    token: string
}

export const authApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        login: builder.mutation<LoginResponse, LoginRequest>({
            query: (request: LoginRequest) => ({
                url: `login`,
                method: 'post',
                body: request
            })
        }),
        logout: builder.mutation<void, void>({
            query: () => ({
                url: `logout`,
                method: 'post'
            })
        }),
        verifyEmail: builder.mutation<User, VerifyEmailInterface>({
            query: (request: VerifyEmailInterface) => {
                const { userId, ...rest } = request

                return {
                    url: `email/verify/${request.userId}`,
                    params: rest,
                    method: 'post'
                }
            },
            transformResponse: (response: { response: User }) => response.response
        }),
        updatePassword: builder.mutation<User, UpdatePasswordRequest>({
            query: (request: UpdatePasswordRequest) => {
                return {
                    url: 'me/password',
                    method: 'PATCH',
                    body: request
                }
            },
            transformResponse: (response: { response: User }) => response.response
        }),
        resetPassword: builder.mutation({
            query: (request: SendResetPasswordEmailRequest) => {
                return {
                    url: 'password/reset',
                    method: 'POST',
                    body: request
                }
            }
        }),
        checkSessionStatus: builder.query<User, void>({
            query: () => {
                return {
                    url: 'session/status'
                }
            },
            transformResponse: (response: { response: User }) => response.response
        }),
        resetPasswordWithToken: builder.mutation({
            query: (request: SendResetPasswordEmailRequestInterface) => {
                const { token, ...rest } = request

                const payload = {
                    ...rest,
                    token
                }

                return {
                    url: `password/reset/${token}`,
                    method: 'POST',
                    body: payload
                }
            }
        })
    })
})

export const {
    useLoginMutation,
    useLogoutMutation,
    useResetPasswordWithTokenMutation,
    useLazyCheckSessionStatusQuery,
    useVerifyEmailMutation,
    useUpdatePasswordMutation,
    useResetPasswordMutation
} = authApi
