import { createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit'
import {
    PaginationResponse,
    ShoppingListItem,
    ShoppingListItemResponse,
    ShoppingListSection,
    ShoppingListSummary
} from 'gwy-sdk'
import { shoppingListApi } from '@api/shoppingList'
import { shoppingListSectionsApi } from '@api/shoppingListSections'
import { shoppingListDefaultSections } from '../constants/shoppingListDefaultSections'

export interface ShoppingListItemsInitialStateInterface {
    requests: Record<string, boolean>
    error?: SerializedError
    shoppingListItems: ShoppingListItem[]
    pagination?: PaginationResponse
    selectedShoppingListItemId?: string
    selectedShoppingListItemIds: string[]
    summary?: ShoppingListSummary
    editItemsLoading?: boolean
    filters: {
        term: string
        sectionId: string
    }
    sections: ShoppingListSection[]
}

const initialState: ShoppingListItemsInitialStateInterface = {
    requests: {
        updateShoppingListItemById: false,
        addShoppingListItemsToCart: false
    },
    shoppingListItems: [],
    selectedShoppingListItemIds: [],
    editItemsLoading: false,
    filters: {
        term: '',
        sectionId: shoppingListDefaultSections.ALL
    },
    sections: []
}

export const shoppingListItemsSlice = createSlice({
    name: 'shoppingListItems',
    initialState,
    reducers: {
        resetShoppingListItemsQuantities: (state) => {
            state.shoppingListItems = state.shoppingListItems?.map((item) => ({
                ...item,
                quantity: 0,
                subtotal: 0
            }))
            state.summary = undefined
        },
        updateShoppingListItemQuantity: (
            state,
            action: PayloadAction<{ shoppingListItemId: string; quantity: number }>
        ) => {
            state.shoppingListItems = state.shoppingListItems?.map((shoppingListItem) => {
                if (shoppingListItem.id !== action.payload.shoppingListItemId) {
                    return shoppingListItem
                }

                return {
                    ...shoppingListItem,
                    quantity: action.payload.quantity
                }
            })
        },
        setSelectedShoppingListItemId: (state, action) => {
            state.selectedShoppingListItemId = action.payload
        },
        setAddingShoppingListItemsToCart: (state, action) => {
            state.requests.addShoppingListItemsToCart = action.payload
        },
        setSections: (state, action) => {
            state.sections = action.payload
        },
        setSelectedShoppingListItemIds: (state, action) => {
            state.selectedShoppingListItemIds = action.payload
        },
        setShoppingListItems: (state, action: PayloadAction<ShoppingListItem[]>) => {
            state.shoppingListItems = action.payload
            state.pagination = undefined
        },
        toggleShoppingListItemIds: (state, action) => {
            const index = state.selectedShoppingListItemIds.indexOf(action.payload)

            if (index === -1) {
                state.selectedShoppingListItemIds.push(action.payload)
            } else {
                state.selectedShoppingListItemIds.splice(index, 1)
            }
        },
        deleteSelectedShoppingListItemIds: (state) => {
            state.shoppingListItems = state.shoppingListItems.filter(
                (item) => !state.selectedShoppingListItemIds.includes(item.id)
            )
        },
        setEditItemsLoading: (state, action) => {
            state.editItemsLoading = action.payload
        },
        setFilters: (state, action: PayloadAction<{ name: string; value: string }>) => {
            state.filters = {
                ...state.filters,
                [action.payload.name]: action.payload.value
            }
        },
        resetFilters: (state) => {
            state.filters = {
                term: '',
                sectionId: String(shoppingListDefaultSections.ALL)
            }
        },
        updateItemPosition: (state, action: PayloadAction<{ oldPos: number; targetPos: number }>) => {
            const slItems = [...state.shoppingListItems]
            const [movedElement] = slItems.splice(action.payload.oldPos, 1)
            slItems.splice(action.payload.targetPos, 0, movedElement)

            state.shoppingListItems = slItems
        }
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(
                shoppingListApi.endpoints.updateShoppingListItem.matchFulfilled,
                (state, action: PayloadAction<ShoppingListItemResponse>) => {
                    state.shoppingListItems = state.shoppingListItems?.map((item) => {
                        if (item.id === action.payload.response.id) {
                            return action.payload.response
                        }

                        return item
                    })
                    state.selectedShoppingListItemId = undefined
                }
            )
            .addMatcher(shoppingListApi.endpoints.updateShoppingListItem.matchPending, (state, action) => {
                state.selectedShoppingListItemId = action.meta.arg.originalArgs.shoppingListItemId
            })
            .addMatcher(shoppingListApi.endpoints.updateShoppingListItem.matchRejected, (state, action) => {
                state.selectedShoppingListItemId = undefined
            })
            .addMatcher(
                shoppingListSectionsApi.endpoints.getShoppingListSectionByCustomerId.matchFulfilled,
                (state, action) => {
                    if (action.payload) {
                        state.sections = action.payload.response
                    }
                }
            )
            .addMatcher(shoppingListApi.endpoints.getSummary.matchFulfilled, (state, action) => {
                if (action.payload) {
                    state.summary = action.payload.response.summary
                    const totalItems = action.payload.response.totalItems
                    if (state.pagination && totalItems) {
                        state.pagination = {
                            ...state.pagination,
                            totalRecords: totalItems
                        }
                    }
                }
            })
            .addMatcher(shoppingListApi.endpoints.getItems.matchFulfilled, (state, action) => {
                if (action.payload) {
                    const { response, paging } = action.payload

                    if (!response.length) {
                        state.shoppingListItems = []
                    } else {
                        state.shoppingListItems = [...state.shoppingListItems, ...response]
                    }

                    state.pagination = paging
                }
            })
            .addMatcher(
                shoppingListSectionsApi.endpoints.deleteShoppingListSectionByCustomerId.matchFulfilled,
                (state, action) => {
                    state.shoppingListItems = state.shoppingListItems.map((item) => {
                        if (String(item.section?.id) === action.meta.arg.originalArgs.shoppingListSectionId) {
                            return {
                                ...item,
                                section: {
                                    id: shoppingListDefaultSections.UNASSIGNED,
                                    name: 'Unassigned',
                                    fixed: true
                                }
                            }
                        }

                        return item
                    })
                }
            )
    }
})

export const {
    setSelectedShoppingListItemId,
    updateShoppingListItemQuantity,
    resetShoppingListItemsQuantities,
    setAddingShoppingListItemsToCart,
    setShoppingListItems,
    setSelectedShoppingListItemIds,
    toggleShoppingListItemIds,
    deleteSelectedShoppingListItemIds,
    setEditItemsLoading,
    setFilters,
    resetFilters,
    setSections,
    updateItemPosition
} = shoppingListItemsSlice.actions
export default shoppingListItemsSlice.reducer
