import { checkoutApi } from '@api/checkout'
import { createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit'
import { Checkout, DeliverySetting, Order } from 'gwy-sdk'

export interface CheckoutInitialStateInterface {
    requests: Record<string, boolean>
    error?: SerializedError
    checkout?: Checkout
    orders?: Order[]
    deliverySettings?: DeliverySetting[]
    customPurchaseOrder?: string
    instructions?: string
    isValidPONumber: boolean
}

const initialState: CheckoutInitialStateInterface = {
    customPurchaseOrder: '',
    instructions: '',
    requests: {
        checkout: false,
        confirmCheckoutByCartId: false
    },
    isValidPONumber: true
}

export const checkoutSlice = createSlice({
    name: 'checkout',
    initialState,
    reducers: {
        setIsValidPONumber(state, action) {
            state.isValidPONumber = action.payload
        },
        setCustomPurchaseOrder(state, action) {
            state.customPurchaseOrder = action.payload
        },
        setInstructions(state, action) {
            state.instructions = action.payload
        },
        updateDeliveryDateByOrderId(state, action: PayloadAction<{ orderId: number; date: string }>) {
            if (state.checkout) {
                state.checkout = {
                    ...state.checkout,
                    checkoutsVendors: state.checkout?.checkoutsVendors?.map((checkoutVendor) => {
                        return {
                            ...checkoutVendor,
                            orders: checkoutVendor.orders?.map((order) => {
                                return {
                                    ...order,
                                    deliveryDate:
                                        Number(order.id) === action.payload.orderId
                                            ? action.payload.date
                                            : order.deliveryDate
                                }
                            })
                        }
                    })
                }
            }
        }
    },
    extraReducers: (builder) => {
        builder.addMatcher(checkoutApi.endpoints.confirmCheckoutByCartId.matchPending, (state, action) => {
            const requestType = action.meta.arg.endpointName
            state.requests[requestType] = true
        })
        builder.addMatcher(checkoutApi.endpoints.confirmCheckoutByCartId.matchFulfilled, (state, action) => {
            const requestType = action.meta.arg.endpointName
            state.requests[requestType] = false
            state.orders = action.payload.response
        })
        builder.addMatcher(checkoutApi.endpoints.confirmCheckoutByCartId.matchRejected, (state, action) => {
            const requestType = action.meta.arg.endpointName
            state.requests[requestType] = false
        })
        builder.addMatcher(checkoutApi.endpoints.getCheckoutByCartId.matchFulfilled, (state, action) => {
            state.checkout = action.payload.response
        })
    }
})

export const { setIsValidPONumber, setCustomPurchaseOrder, setInstructions, updateDeliveryDateByOrderId } =
    checkoutSlice.actions

export default checkoutSlice.reducer
